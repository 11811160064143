import React, { useState, useEffect } from 'react'
import RentalListItems from '../../rentals/RentalListItems'

export function Rental({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [rentals, setRentals] = useState([])
  const settings = item.contentSettings
  const { selectedRentalsPath, rentalListVersion } = appProps
  
  function getViewType() {
    let viewType = null
    switch (settings.viewType) {
      case 'classic':
        viewType = 'version_one'
        break
      case 'minimalistic':
        viewType = 'version_two'
        break
      case 'luxury':
        viewType = 'version_three'
        break
      case 'list':
        viewType = 'version_four'
        break
    }

    return viewType
  }

  useEffect( () => {
    if (settings.selectedRentals.length) {
      setLoading(true)

      $.ajax({
        type: 'GET',
        url: selectedRentalsPath,
        data: {
          items: JSON.stringify(settings.selectedRentals)
        },
        dataType: 'json',
        success: res => {
          setRentals(res.items)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  return (
    <div
      style={{  width: '100%' }}
    >
      <div className='builder-rental-list'>
        <RentalListItems
          appProps={ appProps }
          rentals={ rentals }
          rentalListVersion={ getViewType() }
        />
      </div>
    </div>
  )
}
