import { Rental } from './elements/Rental'
import { RentalList } from './elements/RentalList'

export function additionalElements(props) {
  return {
    rental: {
      component: Rental,
      props: props
    },
    rental_list: {
      component: RentalList,
      props: props
    }
  }
}
