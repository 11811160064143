import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import AvailabilityCheck from '../rentals/widget/AvailabilityCheck'
import { FlashMessage, Loader } from '@reactiveonline/frontend_shared_components'
import { toURLEncoded } from 'helpers/utils'

const DateSelectStep = (props) => {
  const { appProps, currentStep, reservationDateRangePath } = props
  const [ dateRange, setDateRange ] = useState(convertDateRangeToMoment(props.dateRange))
  const flashMessageRef = useRef(null)
  moment.locale(appProps.currentLocale)

  function convertDateRangeToMoment(currentRange) {
    let range = {...currentRange}
    range.start_date = moment(range.start_date)
    range.end_date = moment(range.end_date)

    return range
  }

  if(currentStep > 1) {
    return(
      <div className='card checkout-selected-step flex-box items-center checked_icon' onClick={ props.onSetCurrent }>
        <div className='edit_icon flex-box items-center content-center' />
        <div className='flex-box flex-column items-start content-center'>
          <div>
            <b>{ dateRange.start_date.format('DD MMM YYYY') } - { dateRange.end_date.format('DD MMM YYYY') }</b>
          </div>
          <div className='flex-box items-center' style={{ gap: 5 }}>
            <i 
              className='fa fa-moon' 
              style={{ fontSize: '1.2rem' }} 
            />
            <div>
              { dateRange.nights }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const { isLoading, onNextStep, rental, setIsLoading, availabilityInfo, bookingSettings, minimumDays } = props

  const onDateChange = ({ startDate, endDate }) => {
    setDateRange({
      ...dateRange,
      end_date: endDate,
      start_date: startDate
    })
  }

  const checkAvailability = () => {
    setIsLoading(true)

    Rails.ajax({
      type: 'POST',
      url: reservationDateRangePath,
      dataType: 'json',
      data: toURLEncoded(dateRange),
      success: (result) => {
        props.setAvailableAddons(result.availableAddons)
        setDateRange(convertDateRangeToMoment(result.reservation.date_range))
        onNextStep()
        setIsLoading(false)
      },
      error: (result) => {
        setIsLoading(false)
        flashMessageRef.current.show(result.error, 'error')
      }
    })
  }

  return (
    <>
      <div className="card checkout-step flex-box flex-column">
        <h3><b>{ `${appProps.translations.reservations.change_period_for} ${ rental ? rental.name : '' }` }</b></h3>

        <div className="fields flex-box items-center content-space-between">
          <div className="field">
            <AvailabilityCheck
              appProps={ appProps }
              endDate={ dateRange.end_date }
              onDateChange={onDateChange}
              startDate={ dateRange.start_date }
              availabilityInfo={ availabilityInfo }
              bookingSettings={ bookingSettings }
              minimumDays={ minimumDays }
            />
          </div>

          <div className="field button-wrapper">
            { isLoading ?
              <div className='button inverted flex-box items-center content-center'>
                <Loader size='medium'/>
              </div>
              :
              <a className="button" onClick={ checkAvailability }>
                { appProps.translations.rentals.check_availability }
              </a>
            }
          </div>
        </div>
      </div>

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}

export default DateSelectStep

DateSelectStep.propTypes = {
  currentStep: PropTypes.number,
  dateRange: PropTypes.object,
  isLoading: PropTypes.bool,
  onNextStep: PropTypes.func,
  onSetCurrent: PropTypes.func,
  reservationDateRangePath: PropTypes.string
}
