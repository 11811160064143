import React from 'react'

export default function LocationMap({location}) {
  const { formatted_address, latitude, longitude, embedded_map_ftid } = location

  const iframeSource = 'https://www.google.com/maps/embed?pb=' +
    '!1m18' + //1 marker?
      '!1m12' + //?
        '!1m3' + // map dimensions
          '!1d3142.0941818099595' + `!2d${ longitude }` + `!3d${ latitude }` + //map center
          '!2m3!1f0!2f0!3f0' + '!3m2!1i1024!2i768!4f13.1' + //screen res / size
      '!3m3' +
        '!1m2' + //marker / place search
          `!1s${ embedded_map_ftid }` +
          `!2q${ encodeURIComponent(formatted_address) }` +
      '!5e0' + //map layer
      '!3m2!1sen!2sgr' + ////lang - country
      `!4v${ Date.now() }` + //timestamp epoch
      '!5m2!1sen!2sgr'

  return (
    <div className='builder-google-map'>
      <iframe
        src={ iframeSource }
        width='100%'
        height='500'
        alt='Location Map'
        style={{ border: 0 }}
        allowFullScreen=''
        loading='lazy'
      >
      </iframe>
    </div>
  )
}
