import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import  { IncompleteStep } from './Steps'
import PropTypes from 'prop-types'
import { SelectInput, Loader } from '@reactiveonline/frontend_shared_components'
import ErrorMessage from '../shared/ErrorMessage'
import { validateEmail, validatePhoneNumber } from '@reactiveonline/frontend_shared_components/utils'

const DetailsStep = (props) => {
  const {
    appProps, currentStep, billingAddress, email, isLoading, setIsLoading, addonStepExists, selectedAddons, countries, setCountries
  } = props

  const [currentCountry, setCurrentCountry] = useState({ label: '', value: '' })
  const [availableCountryStates, setAvailableCountryStates] = useState([])
  const [currentState, setCurrentState] = useState({ label: '', value: '' })
  const [phone, setPhone] = useState('')
  const [mobilePhone, setMobilePhone] = useState('')
  const detailsFormRef = useRef(null)

  useEffect( () => {
    if(billingAddress){
      setPhone(billingAddress.phone || '')
      setMobilePhone(billingAddress.mobile_phone || '')

      Rails.ajax({
        url: appProps.getCountriesPath,
        type: 'GET',
        success: (res) => {
          const formattedCountries = res.available_countries.map(c => { return {...c, title: c.label} })
          const countrySelected = formattedCountries.filter(c => c.value == billingAddress.country_numeric_code)[0] ?? formattedCountries[0]
          setCountries(formattedCountries)
          setCurrentCountry(countrySelected)

          fetchCountryStates(countrySelected.value)
        }
      })
    }
  }, [billingAddress])

  const fetchCountryStates = (countryNumericCode) => {
    Rails.ajax({
      url: `${appProps.getCountriesPath}/${countryNumericCode}/states`,
      type: 'GET',
      dataType: 'json',
      success: (res) => {
        const formattedStates = res.available_states.map(s => { return {...s, title: s.label} })
        setAvailableCountryStates(formattedStates)

        let newStateEntry = {}
        if(formattedStates.length > 0) {
          newStateEntry = formattedStates[0]
        } else if(formattedStates.length === 0) {
          newStateEntry = {}
        }

        setCurrentState(newStateEntry)
      }
    })
  }

  if (currentStep < (addonStepExists ? 4 : 3)) {
    return(<IncompleteStep stepTitle={ appProps.translations.reservations.reservation_details }/>)
  }

  if (currentStep > (addonStepExists ? 4 : 3)){
    const { onSetCurrent } = props

    return(
      <div className='card checkout-selected-step flex-box items-center checked_icon' onClick={ onSetCurrent }>
        <div className='edit_icon'></div>
        <div className='rental-details'>
          <div className='details'>
            <div className='detail'>
              <b>
                { `${ appProps.translations.reservations.full_name }: ${ billingAddress.full_name }` }
              </b>
            </div>
            <div className='detail'>
              { `${ appProps.translations.reservations.email }: ${ email }` }
              </div>
            <div className='detail'>
              { `${ appProps.translations.reservations.phone }: ${ billingAddress.phone || billingAddress.mobile_phone }` }
            </div>
          </div>
        </div>
      </div>
    )
  }

  const { notes, reservationPath } = props
  const { city, full_name, postal_code, state_region_code, street } = billingAddress

  const clearErrors = () => {
    document.querySelectorAll('[id$="-error"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')
  }

  const setCountry = (countryEntry) => {
    setCurrentCountry(countryEntry)
    fetchCountryStates(countryEntry.value)
  }

  const handleDetailsFormSubmit = (e) => {
    e.preventDefault()
    clearErrors()

    let noErrors = true
    const email = detailsFormRef.current['reservation[email]'].value
    const fullName = detailsFormRef.current['reservation[billing_address_attributes][full_name]'].value
    const address = detailsFormRef.current['reservation[billing_address_attributes][street]'].value
    const formCity = detailsFormRef.current['reservation[billing_address_attributes][city]'].value
    const postalCode = detailsFormRef.current['reservation[billing_address_attributes][postal_code]'].value

    if ( email.length === 0 || !validateEmail(email)) {
      ErrorMessage("reservation-email", "red", "reservation-email-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( fullName.length === 0 ) {
      ErrorMessage("billing-full-name", "red", "billing-full-name-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( !validatePhoneNumber(phone) && !validatePhoneNumber(mobilePhone) ) {
      ErrorMessage("billing-phone", "red", "billing-phone-error", `${appProps.translations.flash_messages.phone_invalid}`, "red")
      noErrors = false
    }
    if ( address.length === 0 ) {
      ErrorMessage("billing-street", "red", "billing-street-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( formCity.length === 0 ) {
      ErrorMessage("billing-city", "red", "billing-city-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( !currentCountry ) {
      ErrorMessage("billing-country", "red", "billing-country-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( postalCode.length === 0 ) {
      ErrorMessage("billing-postal_code", "red", "billing-postal_code-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }
    if ( availableCountryStates.length > 0 && (currentState?.value || '').length === 0 ) {
      ErrorMessage("billing-area", "red", "billing-area-error", `${appProps.translations.flash_messages.fill_all_required_fields}`, "red")
      noErrors = false
    }

    if (noErrors) {
      setIsLoading(true)

      let formdata = new FormData(detailsFormRef.current)
      formdata.append('reservation[billing_address_attributes][country_numeric_code]', currentCountry.value)

      if (currentState) {
        formdata.append('reservation[billing_address_attributes][state_region_code]', currentState.value)
      }

      if (addonStepExists && selectedAddons?.length > 0) {
        const addonData = selectedAddons.map(item => ({ id: item.id, quantity: item.quantity || '' }))

        formdata.append('addons', JSON.stringify(addonData))
      }

      if(appProps.currentUserEmail) {
        formdata.append("reservation[email]", appProps.currentUserEmail)
      }

      Rails.ajax({
        url: appProps.reservationPath,
        type: 'PATCH',
        contentType: 'application/json',
        dataType: 'json',
        data: formdata,
        success: (response, statusText, xhr) => {
          props.onNextStep()
        },
        error: (response, statusText, xhr) => {
        }
      })
    }
  }

  return(
    <div className="card checkout-step flex-box flex-column">
      <h3><b>{ appProps.translations.reservations.contact_information_title }</b></h3>
      <div className='step-editor flex-box flex-wrap'>
        <form method='post' action={appProps.reservationPath} onSubmit={handleDetailsFormSubmit} ref={detailsFormRef}>
          <div className="field">
            <div className='flex-box items-center'>
              <label>
                { appProps.translations.reservations.email }
              </label>
              <div style={{marginRight: '5px'}}>*</div>
            </div>
              <input
                minLength={6}
                name='reservation[email]'
                id='reservation-email'
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                placeholder='johndoe@example.com'
                disabled={!!appProps.currentUserEmail}
                type='email'
                defaultValue={appProps.currentUserEmail || email}
              />
          </div>

          <div className="field">
            <div className='flex-box items-center'>
              <label>
                { appProps.translations.reservations.full_name }
              </label>
              <div style={{marginRight: '5px'}}>*</div>
            </div>

            <input
              name='reservation[billing_address_attributes][full_name]'
              placeholder='John Doe'
              id='billing-full-name'
              type='text'
              defaultValue={full_name}
            />
          </div>

          <div className="field-columns">
            <div className="field">
              <label>
                { appProps.translations.reservations.phone }
              </label>
              <input
                name='reservation[billing_address_attributes][phone]'
                id='billing-phone'
                placeholder='+30 123 456 7890'
                type="tel"
                value={ phone }
                onChange={ event => setPhone(event.target.value.match(/^(\+?\d{0,20})?$/) ? event.target.value : phone) }
              />
            </div>

            <div className="field">
              <label>
                { appProps.translations.reservations.mobile }
              </label>
              <input
                name='reservation[billing_address_attributes][mobile_phone]'
                id='billing-mobile_phone'
                placeholder='+30 123 456 7890'
                type="tel"
                value={ mobilePhone }
                onChange={ event => setMobilePhone(event.target.value.match(/^(\+?\d{0,20})?$/) ? event.target.value : mobilePhone) }
              />
            </div>
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.address }
                </label>
                <div style={{marginRight: 5}}>*</div>
              </div>
              <input
                name='reservation[billing_address_attributes][street]'
                placeholder='1 Example street'
                id='billing-street'
                type="text"
                defaultValue={ street }
              />
            </div>

            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.city }
                </label>
                <div style={{ marginRight: '5px' }}>*</div>
              </div>
              <input id="billing-city"
                name='reservation[billing_address_attributes][city]'
                placeholder='Athens'
                type="text"
                defaultValue={city}
              />
            </div>
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.postal_code }
                </label>
                <div style={{marginRight: '5px'}}>*</div>
              </div>
              <input id="billing-postal_code"
                name='reservation[billing_address_attributes][postal_code]'
                placeholder='123 45'
                type="text"
                defaultValue={postal_code}
              />
            </div>

            <div className="field">
              <SelectInput
                inputId='billing-country'
                wrapperClasses='checkout-step'
                label={ `${ appProps.translations.reservations.country }*` }
                options={ countries.map(country => ({ ...country, title: country.label })) }
                currentOption={ currentCountry.value || null }
                setCurrentOption={ setCountry }
              />
            </div>

            { currentCountry && availableCountryStates.length > 0 &&
              <div className="field">
                <SelectInput
                  inputId='billing-area'
                  wrapperClasses='checkout-step'
                  label={ `${ appProps.translations.reservations.state }*` }
                  options={ availableCountryStates }
                  currentOption={ currentState.value || null }
                  setCurrentOption={ setCurrentState }
                />
              </div>
            }

            <div className="field notes-wrapper">
              <div className='flex-box items-center'>
                <label>
                  { appProps.translations.reservations.reservation_notes }
                </label>
              </div>
              <textarea
                name='reservation[notes]'
                placeholder='e.g need extra blankets, have baby crib in main room'
                defaultValue={notes}
              />
            </div>

          </div>

          <div className='flex-box content-end' style={{ marginTop: 40, width: '100%' }}>
            { isLoading ?
              <div className='button inverted flex-box items-center content-center'>
                <Loader size='medium'/>
              </div>
            :
              <button className='button'>
                { appProps.translations.reservations.next_step }
              </button>
            }
          </div>
        </form>
      </div>
    </div>
  )
}


DetailsStep.propTypes = {
  billingAddress: PropTypes.object,
  currentStep: PropTypes.number,
  email: PropTypes.string,
  getCountriesPath: PropTypes.string,
  isLoading: PropTypes.bool,
  notes: PropTypes.string,
  onNextStep : PropTypes.func,
  onSetCurrent: PropTypes.func,
  reservationPath: PropTypes.string,
}

export default DetailsStep
