import React, { useState, Fragment } from 'react'
import { NO_IMAGE_SOURCE } from '../rentals/constants'
import { Modal } from '@reactiveonline/frontend_shared_components'

const ShowMore = ({ appProps, rental }) => {
  const { amenities, arrangements, description, images, information, location, name, rooms } = rental
  const [showMore, setShowMore] = useState(false)

  const onModalOpen = (e) => {
    e.stopPropagation()
    setShowMore(true)
  }

  return(
    <>
      <div className='modal-opener'>
        <button className='button plain' type='button' onClick={ onModalOpen }>
          { appProps.translations.reservations.more_information }
        </button>
      </div>

      { showMore &&
        <>
          <Modal
            visible
            closeModal={ () => setShowMore(false) }
            mode='large'
          >
            <div className='checkout-modal-wrapper'>
              <img src={ images.length > 0 ? images[0].preview : NO_IMAGE_SOURCE } alt='rental-image' className='show-more-rental-image'/>
              <h5> { name } </h5>

              <div className='rental-amenity-list'>
                { arrangements.length > 0 &&
                  <div className='amenities-list'>
                    <div className='section-title'>
                      { appProps.translations.rentals.sleep_arrangements }
                    </div>
                    <div className='list-items'>
                      {arrangements.map((arrangement, index) => {
                        return(
                          <div className='list-item' key={index}> { `${arrangement.quantity} ${arrangement.presentation}` } </div>
                        )
                      })}
                    </div>
                  </div>
                }

                { rooms.length > 0 &&
                  <div className='amenities-list'>
                    <div className='section-title'>
                      { appProps.translations.rentals.amenities }
                    </div>
                    <div className='list-items'>
                      { rooms.map((room, index) => {
                        return(
                          <div className='list-item'  key={index}> { room.presentation }{index < rooms.length-1 ? ',' : '' } </div>
                        )
                      })}
                    </div>
                  </div>
                }

                { amenities.length > 0 &&
                  <div className='amenities-list'>
                    <div className='section-title'>
                      { appProps.translations.rentals.facilities }
                    </div>
                    <div className='list-items'>
                      { amenities.map((amenity, index) => {
                        return(
                          <div className='list-item'  key={index}> { amenity.presentation }{index < amenities.length-1 ? ',' : '' } </div>
                        )
                      })}
                    </div>
                  </div>
                }

                { description && description.length > 0 ?
                  <div className='amenities-list'>
                    <div className='section-title'>
                      { appProps.translations.reservations.rental_description }
                    </div>
                    <div dangerouslySetInnerHTML={{__html: description}} className="text"></div>
                  </div>
                  : null
                }
              </div>

            </div>
          </Modal>
        </>
      }
    </>
  )
}

ShowMore.defaultProps = {
  amenities: [],
  arrangements: [],
  description: [],
  images: [],
  information: {},
  location: {},
  name: '',
  rooms: []
}

export default ShowMore
