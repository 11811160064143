import React from 'react'
import ReservationHistory from "../shared/ReservationHistory"

export default function Reservations({
  appProps, pageTitle, reservations
}) {
  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>

      <ReservationHistory
        appProps={ appProps }
        reservations={ reservations }
      />
    </>
  )

}
