import React, { useState, useEffect, useRef, Fragment } from 'react'
import { FlashMessage, InputField, Loader, Modal, SelectInput } from '@reactiveonline/frontend_shared_components'
import { AccountNewCard } from '@reactiveonline/frontend_shared_components/billing'

const initialBillingDetails = {
  fullName: '',
  email: '',
  phone: '',
  mobilePhone: '',
  street: '',
  city: '',
  postalCode: '',
  country: {},
  state: {},
}

export default function Billing(props) {
  const paymentMethods = props.payments.filter(p => p.cards.length > 0 )

  let initialCards = {}
  props.payments.forEach((p, i) => {
    initialCards[p.id] = p.cards
  })

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState([])
  const [availableCountries, setAvailableCountries] = useState([])
  const [availableCountryStates, setAvailableCountryStates] = useState([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods.length > 0 ? paymentMethods[0].id : 0)
  const [cards, setCards] = useState(initialCards)
  const [billingDetails, setBillingDetails] = useState(props.currentBilling?.address ?? initialBillingDetails)
  const [showBillingDetailsModal, setShowBillingDetailsModal] = useState(false)
  const [showAddNewCardModal, setShowAddNewCardModal] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [saveCardLoading, setSaveCardLoading] = useState(false)

  const flashMessageRef = useRef(null)

  useEffect(() => {
    if(props.currentBilling?.address && props.currentBilling?.address.country) {
      fetchCountryStates(props.currentBilling.address.country.value)
    }
  }, [])

  const setPrimaryCard = (card, paymentMethodId) => {
    setLoading(true)
    const { setCurrentCardPath } = props

    if (!card.current) {
      let fd = new FormData()
      fd.append('card_details[id]', card.id)
      fd.append('card_details[payment_method_id]', paymentMethodId)

      Rails.ajax({
        type: 'POST',
        url: setCurrentCardPath,
        dataType: 'json',
        data: fd,
        success: res => {
          if (res.cards) {
            let newCards = { ...cards }
            newCards[paymentMethodId] = res.cards
            setCards(newCards)
            setLoading(false)
            flashMessageRef.current.show(props.appProps.translations.reservation.card_set_default, 'success', 5000)
          }
        },
        error: res => {
          if (res.error) {
            setLoading(false)
            flashMessageRef.current.show(res.error, 'error')
          }
        }
      })
    }
  }

  const togglePaymentMethod = (paymentMethodId) => {
    selectedPaymentMethod == setSelectedPaymentMethod(paymentMethodId)
  }

  const handleAddNewCardClick = () => {
    if (availablePaymentMethods.length === 0) {
      setLoading(true)

      Rails.ajax({
        url: `${ props.appProps.paymentMethodsPath }?payment_type=indirect`,
        type: 'GET',
        success: (res) => {
          setAvailablePaymentMethods(res)
          setShowAddNewCardModal(true)
          setLoading(false)
        }
      })
    } else {
      setShowAddNewCardModal(true)
    }
  }

  const handleCreateEditBillingDetailsClick = () => {
    if (availableCountries.length === 0) {
      setLoading(true)

      Rails.ajax({
        url: props.appProps.getCountriesPath,
        type: 'GET',
        dataType: 'json',
        success: (res) => {
          const formattedCountries = res.available_countries.map(c => { return {...c, title: c.label} })
          setAvailableCountries(formattedCountries)
          setShowBillingDetailsModal(true)
          setLoading(false)

          if (!billingDetails.country.value) {
            const defaultCountry = formattedCountries.find(c => c.value === 300)

            setBillingDetails(prevState => {
              return {
                ...prevState,
                country: defaultCountry
              }
            })

            fetchCountryStates(defaultCountry.value, true)
          } else {
            fetchCountryStates(billingDetails.country.value)
          }
        }
      })

      Rails.ajax({
        type: 'GET',
        url: props.appProps.getAccountInfoPath,
        dataType: 'json',
        success: res => {
          if(res.account) {
            setEmail(res.account.email)
          }
        }
      })
    } else {
      setShowBillingDetailsModal(true)
    }
  }

  const fetchCountryStates = (countryNumericCode, setFirstState = false) => {
    Rails.ajax({
      url: `${props.appProps.getCountriesPath}/${countryNumericCode}/states`,
      type: 'GET',
      dataType: 'json',
      success: (res) => {
        const formattedStates = res.available_states.map(s => { return {...s, title: s.label} })
        setAvailableCountryStates(formattedStates)

        let newStateEntry = {}
        if(setFirstState && formattedStates.length > 0) {
          newStateEntry = formattedStates[0]
        } else if(formattedStates.length === 0) {
          newStateEntry = {}
        } else if(!!billingDetails.state?.value) {
          newStateEntry = billingDetails.state
        }

        setBillingDetails(prevState => {
          return {
            ...prevState,
            state: newStateEntry
          }
        })
      }
    })
  }

  const destroyCard = (card, paymentMethodId) => {
    setLoading(true)
    const { destroyCardPath } = props

    let fd = new FormData()
    fd.append('card_details[id]', card.id)

    Rails.ajax({
      type: 'POST',
      url: destroyCardPath,
      dataType: 'json',
      data: fd,
      success: res => {
        let newCards = { ...cards }
        newCards[paymentMethodId] = newCards[paymentMethodId].filter(c => c.id != card.id)
        setCards(newCards)
        setLoading(false)
        flashMessageRef.current.show(props.appProps.translations.reservation.card_deleted, 'success', 3000)
      },
      error: res => {
        if (res.error) {
          setLoading(false)
          flashMessageRef.current.show(res.error, 'error')
        }
      }
    })
  }

  const handleSaveBillingDetailsClick = () => {
    const fd = new FormData()

    if (props.currentBilling) {
      fd.append('associable_address[id]', props.currentBilling.id)
      fd.append('associable_address[address_id]', props.currentBilling.address.id)
      fd.append('associable_address[address_attributes][id]', props.currentBilling.address.id)
    }

    if (email) {
      fd.append('associable_address[address_attributes][email]', email)
    }

    fd.append('associable_address[address_attributes][full_name]', billingDetails.fullName)
    fd.append('associable_address[address_attributes][phone]', billingDetails.phone)
    fd.append('associable_address[address_attributes][mobile_phone]', billingDetails.mobilePhone)
    fd.append('associable_address[address_attributes][street]', billingDetails.street)
    fd.append('associable_address[address_attributes][city]', billingDetails.city)
    fd.append('associable_address[address_attributes][postal_code]', billingDetails.postalCode)
    fd.append('associable_address[address_attributes][state_region_code]', billingDetails.state.value)
    fd.append('associable_address[address_attributes][country_numeric_code]', billingDetails.country.value)
    fd.append('associable_address[address_attributes][usage]', 'billing')

    setSaveCardLoading(true)

    Rails.ajax({
      type: props.currentBilling ? 'PATCH' : 'POST',
      url: `/${ props.appProps.currentLocale }/associable_addresses${ props.currentBilling ? `/${ props.currentBilling.id }` : '' }`,
      dataType: 'json',
      data: fd,
      success: _res => {
        window.location.reload()
      },
      error: res => {
        flashMessageRef.current.show(res.errors, 'error')
        setSaveCardLoading(true)
      }
    })
  }

  let paymentProviders =
    <div className='card'>
      <div className='no_billing payment_icon' style={{ marginBottom: '20px' }}>
        <h2>{ props.appProps.translations.account.no_billing }</h2>
      </div>

      { props.currentBilling &&
        <div className='flex-box items-center content-end'>
          <button
            onClick={ _e => handleAddNewCardClick() }
          >
            { props.appProps.translations.general.button_create }
          </button>
        </div>
      }

      <FlashMessage ref={ flashMessageRef } />
    </div>
  if (props.payments.length && paymentMethods.length) {
      paymentProviders =
      <div className='card'>
        <div className='payment-providers-wrapper'>
          <h5>{ props.appProps.translations.account.billing_subtitle }</h5>
          <div className='vertical-tabs'>
            <div className='tab-head'>
              { paymentMethods.map(paymentMethod => (
                <div className={`vertical-tab-item ${selectedPaymentMethod == paymentMethod.id ? 'selected' : ''}`} key={ paymentMethod.id } onClick={ () => togglePaymentMethod(paymentMethod.id) }>
                  { paymentMethod.title }
                </div>
              ))}
            </div>
            <div className='tab-body'>
              <div id="payment-methods-list">
                { paymentMethods.map(paymentMethod => {
                    if (selectedPaymentMethod == paymentMethod.id && cards[paymentMethod.id]) {
                      return (
                        <React.Fragment key={ paymentMethod.id }>
                          <div className="payment-method-title option">
                            <div className={`name ${paymentMethod.type}`}>
                              { paymentMethod.title }
                            </div>
                          </div>
                          <div className='tokenized-cards-wrapper'>
                            { cards[paymentMethod.id].map(card => (
                              <div
                                key={ card.id }
                                onClick={ () => setPrimaryCard(card, paymentMethod.id) }
                                className={ `pointer tokenized-card billing ${ card.current ? 'current' : '' }` }
                              >
                                <span
                                  className="tokenized-card-details">
                                    <img src={ `https://storage.googleapis.com/reactive_files_eu/payment_processors/card_issuers/${ card.issuer }.svg` } style={{ width: 50 }}/>
                                    { ['amex', 'diners', 'discover', 'maestro', 'mastercard', 'visa'].includes(card.issuer) &&
                                      <div className='tokenized-card-pan'>
                                        { `********${ card.panLast }` }
                                      </div>
                                    }
                                </span>
                                { !card.current && <span className="delete-card-icon" onClick={ e => { e.stopPropagation(); destroyCard(card, paymentMethod.id) } }/> }
                              </div>
                              ))
                            }
                          </div>
                        </React.Fragment>
                      )
                    }
                  })
                }
              </div>
            </div>
          </div>
        </div>

        { props.appProps.keepCards &&
          <div className='flex-box items-center content-end'>
            <button
              onClick={ _e => handleAddNewCardClick() }
            >
              { props.appProps.translations.general.button_create }
            </button>
          </div>
        }

        <FlashMessage ref={ flashMessageRef } />
      </div>
    }

  return (
    <>
      { props.currentBilling &&
        <div className='billing-info-wrapper'>
          <h3>{ props.pageTitle }</h3>
          <div style={{ fontSize: 13, marginBottom: 20 }}>
            { props.appProps.translations.reservations.billing_info }
          </div>
          <div className='billing-address flex-column'>
            <div className='order_history_wrapper card'>
              <div className="heading flex-box items-center">
                <h3>{ props.pageTitle }</h3>
                <div
                  className="button plain"
                  onClick={ _e => handleCreateEditBillingDetailsClick() }
                >
                  <i className="fa-light fa-pencil" />
                </div>
              </div>

              <div className='history_headers'>
                <div className='row'>
                  { props.appProps.translations.reservations.full_name }
                </div>
                <div className='row'>
                  { props.appProps.translations.reservations.phone }
                </div>
                <div className='row'>
                  { props.appProps.translations.reservations.mobile }
                </div>
                <div className='row'>
                  { props.appProps.translations.reservations.address }
                </div>
              </div>
              <div className='history_rows'>
                <div className='row date'>
                  { props.currentBilling.address.fullName }
                </div>

                <div className='row number'>
                  { props.currentBilling.address.mobilePhone ? props.currentBilling.address.mobilePhone : '-' }
                </div>

                <div className='row total'>
                  { props.currentBilling.address.phone ? props.currentBilling.address.phone : '-' }
                </div>

                <div className='row delivered'>
                  { props.currentBilling.address.formattedAddress }
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      { !props.currentBilling &&
        <div className='billing-info-wrapper'>
          <h3>{ props.pageTitle }</h3>
          <div style={{ fontSize: 13, marginBottom: 20 }}>
            { props.appProps.translations.reservations.billing_info }
          </div>

          <div className='card'>
            <div className='card-content'>
              <div className='card-header' style={{ marginBottom: '20px' }}>
                <div className='card-title flex-box items-center content-space-between'>
                  <div>
                    { props.pageTitle }
                  </div>

                  <button
                    onClick={ _e => handleCreateEditBillingDetailsClick() }
                  >
                    { props.appProps.translations.general.button_create }
                  </button>
                </div>
              </div>

              <div className='card-body'>
                <div>
                  { props.appProps.translations.account.billing_info_help_text }
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      { paymentProviders }

      { showBillingDetailsModal &&
        <Modal
          visible
          mode='large'
          closeModal={ _e => setShowBillingDetailsModal(false) }
          acceptAction={ _e => handleSaveBillingDetailsClick() }
          saveText={ props.appProps.translations.general.button_save }
        >
          <div>
            <h3 style={{ marginBottom: '20px' }}>
              { props.appProps.translations.account.billing }
            </h3>

            <div className='flex-box flex-wrap' style={{ width: '100%', gap: '4%' }}>
              <div style={{ width: '48%' }}>
                <InputField
                  label={ `${props.appProps.translations.reservations.full_name} *` }
                >
                  <input
                    type="text"
                    value={ billingDetails.fullName }
                    onChange={ (e) => setBillingDetails(prevState => ({ ...prevState, fullName: e.target.value } )) }
                  />
                </InputField>
              </div>

              <div style={{ width: '48%' }}>
                <InputField
                  label={ `${props.appProps.translations.reservations.address} *` }
                >
                  <input
                    type="text"
                    value={ billingDetails.street }
                    onChange={ (e) => setBillingDetails(prevState => ({ ...prevState, street: e.target.value } )) }
                  />
                </InputField>
              </div>
            </div>

            <div className='flex-box flex-wrap' style={{ width: '100%', gap: '4%' }}>
              <div style={{ width: '48%' }}>
                <InputField
                  label={ `${props.appProps.translations.reservations.phone} *` }
                >
                  <input
                    type="text"
                    value={ billingDetails.phone }
                    onChange={ (e) => setBillingDetails(prevState => ({ ...prevState, phone: e.target.value } )) }
                  />
                </InputField>
              </div>

              <div style={{ width: '48%' }}>
                <InputField
                  label={ props.appProps.translations.reservations.mobile }
                >
                  <input
                    type="text"
                    value={ billingDetails.mobilePhone }
                    onChange={ (e) => setBillingDetails(prevState => ({ ...prevState, mobilePhone: e.target.value } )) }
                  />
                </InputField>
              </div>
            </div>

            <div className='flex-box flex-wrap' style={{ width: '100%', gap: '4%' }}>
              <div style={{ width: '48%' }}>
                <InputField
                  label={ `${props.appProps.translations.reservations.city} *` }
                >
                  <input
                    type="text"
                    value={ billingDetails.city }
                    onChange={ (e) => setBillingDetails(prevState => ({ ...prevState, city: e.target.value } )) }
                  />
                </InputField>
              </div>

              <div style={{ width: '48%' }}>
                <InputField
                  label={ `${props.appProps.translations.reservations.postal_code} *` }
                >
                  <input
                    type="text"
                    value={ billingDetails.postalCode }
                    onChange={ (e) => setBillingDetails(prevState => ({ ...prevState, postalCode: e.target.value } )) }
                  />
                </InputField>
              </div>
            </div>

            <div className='flex-box flex-wrap' style={{ width: '100%', gap: '4%' }}>
              <div style={{ width: '48%' }}>
                <SelectInput
                  label={ `${props.appProps.translations.reservations.country} *` }
                  options={ availableCountries }
                  currentOption={ billingDetails.country?.value }
                  setCurrentOption={ country => {
                    fetchCountryStates(country.value, true)
                    setBillingDetails(prevState => ({ ...prevState, country: country, state: {} } ))
                  }}
                />
              </div>

              <div style={{ width: '48%' }}>
                { billingDetails.country && availableCountryStates.length > 0 &&
                  <SelectInput
                    label={ props.appProps.translations.reservations.state }
                    options={ availableCountryStates }
                    currentOption={ billingDetails.state?.value }
                    setCurrentOption={ state => setBillingDetails(prevState => ({ ...prevState, state: state } )) }
                  />
                }
              </div>
            </div>

          </div>

          { loading &&
            <Loader size='medium' />
          }
        </Modal>
      }

      { showAddNewCardModal &&
        <AccountNewCard
          appProps={ props.appProps }
          billingDetails={ props.currentBilling.address }
          closeAddNewCardModal={ _e => setShowAddNewCardModal(false) }
          flashMessageRef={ flashMessageRef }
          loading={ saveCardLoading }
          paymentMethods={ availablePaymentMethods }
          setLoading={ setSaveCardLoading }
          stripePublishableKey={ props.stripePublishableKey }
        />
      }

      { loading && <Loader size='large' fullPage /> }

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}
