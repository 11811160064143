export function findTypeOptions(parent, step, totalSteps) {
  switch (parent) {
    case "payment":
      if (step == 1) {
        return ["at_booking_time"]
      }
      else if (step == 2) {
        return ["days_before_arrival", "days_after_arrival", "days_before_departure"]
      } else {
        return ["days_before_arrival", "days_after_arrival", "days_before_departure"]
      }
      break
    case "refund":
      return ["days_before_arrival"]
    case "warranty":
      if (step == 1) {
        return ["days_before_arrival", "days_after_arrival", "days_before_departure"]
      } else {
        return ["days_after_departure"]
      }
    default:
      return []
  }
}

export function findPriceOptions(parent, step, totalSteps) {
  switch (parent) {
    case "payment":
      switch (totalSteps) {
        case 1:
          return ["percentage"]
        case 2:
          return (step == 1 ? ["percentage", "flat"] : ["residual"])
        case 3:
          return (step == 3 ? ["residual"] : ["percentage"])
      }
      break
    case "refund":
      return ["percentage", "flat"]
    case "warranty":
      return ["percentage", "flat"]
    default:
      return []
  }
}

export function paymentStepDescription(step, currency) {
  let description = ''
  let percentage_description = `${step.percentage}% `
  let amount_description = `${currency.symbol_first ? currency.symbol : ''}${step.amount}${!currency.symbol_first ? currency.symbol : ''} `

  switch (step.charge_type) {
    case 'flat':
      description += amount_description
      break
    case 'percentage':
      description += percentage_description
      break
    case 'both':
      description += `${percentage_description} ${Travel.translations.or} ${amount_description} `
      break
    default:
      description += `${Travel.translations.policies.paymentSchedule.view.payment_types.residual} `
  }
  if (step.type !== 'at_booking_time') {
    description += `${Travel.translations.policies.paymentSchedule.view.due} `
  }
  description += `${step.days} ${Travel.translations.policies.paymentSchedule.view.payment_fields[step.type]}.`

  return description
}

export function paidAmount(step, total_amount, paid_amount = null) {
  switch (step.charge_type) {
    case 'flat':
      return step.amount <= total_amount ? step.amount : total_amount
    case 'percentage':
      return total_amount * (parseFloat(step.percentage) / 100.0)
    case 'both':
      if (step.amount <= total_amount) {
        return step.amount
      } else {
        return total_amount * (parseFloat(step.percentage) / 100.0)
      }
    case 'residual':
      return (total_amount - paid_amount).toFixed(2)
  }
}
