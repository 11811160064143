import PropTypes from 'prop-types';
import React from 'react'

const PriceDisplay = ({ pricePerNight, totalPrice, taxText }) => {
  return(
    <div className='price-per-night-display'>
      { pricePerNight &&
        <div className='price-per-night'>
          { pricePerNight }
        </div>
      }

      { totalPrice &&
        <div className='price-total-wrapper'>
          <div className='price-total'>
            { totalPrice }
          </div>
          { taxText &&
            <div className='price-total-tax'>
              { taxText }
            </div>
          }
        </div>
      }
    </div>
  )
}

PriceDisplay.propTypes = {
  label: PropTypes.string,
  price: PropTypes.string,
  trailingLabel: PropTypes.string
}


export default PriceDisplay
