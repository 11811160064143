import React from "react"

export default function ReservationPolicies( props ) {
  const mainProps = props.appProps

  return (
    <>
      { props.reservationStatus &&
        <>
          { props.policyEnabled &&
            <div className="card order-detail-block">
              <div className="order-detail-block-title">
                { mainProps.translations.reservation.payment_policies }
              </div>
              <div className="order-detail-block-description flex-box flex-column">
                <div>{ props.policyType }</div>
                <div>{ props.policySymbol } { props.policyText }</div>
              </div>
            </div>
          }

          { props.warranty &&
            <div className="card order-detail-block">
              <div className="order-detail-block-title">
                { mainProps.translations.reservation.warranty_title }
              </div>
              <div className="order-detail-block-description">
                { props.warranty }
              </div>
            </div>
          }
        </>
      }
    </>
  )
}
