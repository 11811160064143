import React, { useState, useEffect, useRef } from 'react'
import PaymentStep from './PaymentStep'
import { AuthorizableModal, Loader, SelectInput } from '@reactiveonline/frontend_shared_components'
import AgreementsModal from './AgreementsModal'
import TermsModal from './TermsModal'
import Summary from './Summary'

export default function PaymentLink(props) {
  const { appProps, reservationDateRangePath, disabledCheckout, agreements, legalTerms, reservation } = props
  const { translations } = appProps
  const [billingAddress, setBillingAddress] = useState(props.userAddress)
  const [dateRange, setDateRange] = useState(reservation.date_range)
  const [email, setEmail] = useState(reservation.email)
  const [guests, setGuests] = useState(reservation.guests)
  const [notes, setNotes] = useState(reservation.notes)
  const [rental, setRental] = useState(reservation.rental)
  const [showPaymentIframe, setShowPaymentIframe] = useState(false)
  const [uid, setUid] = useState(reservation.uid)
  const [cost, setCost] = useState(reservation.cost)
  const [countries, setCountries] = useState([])
  const [currentCountry, setCurrentCountry] = useState({ label: '', value: '' })
  const [availableCountryStates, setAvailableCountryStates] = useState([])
  const [currentState, setCurrentState] = useState({ label: '', value: '' })
  const [phone, setPhone] = useState(billingAddress.phone || '')
  const [mobilePhone, setMobilePhone] = useState(billingAddress.mobile_phone || '')
  const [showAgreementsModal, setShowAgreementsModal] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const detailsFormRef = useRef(null)

  useEffect( () => {
    setLoading(true)

    Rails.ajax({
      url: appProps.getCountriesPath,
      type: 'GET',
      success: res => {
        const formattedCountries = res.available_countries.map(c => { return {...c, title: c.label} })
        const countrySelected = formattedCountries.filter(c => c.value == billingAddress.country_numeric_code)[0] ?? formattedCountries[0]
        setCountries(formattedCountries)
        setCurrentCountry(countrySelected)
      }
    })
  }, [])

  useEffect(() => {
    if (currentCountry.value != '') {
      setLoading(true)
  
      Rails.ajax({
        url: `${ appProps.getCountriesPath }/${ currentCountry.value }/states`,
        type: 'GET',
        dataType: 'json',
        success: (res) => {
          const formattedStates = res.available_states.map(s => { return {...s, title: s.label} })
          setAvailableCountryStates(formattedStates)
  
          let newStateEntry = {}
          if (formattedStates.length > 0) {
            newStateEntry = formattedStates.find(s => s.value == billingAddress.state_region_code) ?? formattedStates[0]
          } else if (formattedStates.length === 0) {
            newStateEntry = {}
          }
  
          setCurrentState(newStateEntry)
          setLoading(false)
        }
      })
    }
  }, [currentCountry])

  const renderCountryLabel = () => (
    <div className='flex-box items-center'>
      <label>
        { translations.reservations.country }
      </label>
      <div style={{ paddingLeft:2, marginRight: 5 }}>*</div>
    </div>
  )

  const renderStateLabel = () => (
    <div className='flex-box items-center'>
      <label>
        { translations.reservations.state }
      </label>
      <div style={{ paddingLeft: 2, marginRight: '5px' }}>*</div>
    </div>
  )

  return (
    <>
      <div className='main-container'>
        { props.disabledCheckout ?
          <div className="main-container">
            <div className="not-found-wrapper flex-box flex-column items-center content-center">
              <h2>
                { translations.general.checkout_closed }
              </h2>
            </div>
          </div>
          :
          ( !appProps.user && reservation.rental.policy ?
            <AuthorizableModal
              appProps={ appProps }
              authorizableScreen={ 'login' }
            />
            :
            <div className='checkouts-wrapper'>
              <Summary
                appProps={ appProps }
                currentRental={ rental }
                cost={ cost }
              />
              <div className='checkout-container'>
                <div className="card checkout-step flex-box flex-column">
                  <h3><b>{ translations.reservations.contact_information_title }</b></h3>
                  <div className='step-editor flex-box flex-wrap'>
                    <form method='post' onSubmit={() => {}} ref={detailsFormRef}>
                      <div className="field">
                        <div className='flex-box items-center'>
                          <label>
                            { translations.reservations.email }
                          </label>
                          <div style={{marginRight: '5px'}}>*</div>
                        </div>
                          <input
                            minLength={6}
                            name='reservation[email]'
                            id='reservation-email'
                            pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                            placeholder='johndoe@example.com'
                            disabled={!!appProps.currentUserEmail}
                            type='email'
                            defaultValue={appProps.currentUserEmail || email}
                          />
                      </div>

                      <div className="field">
                        <div className='flex-box items-center'>
                          <label>
                            { translations.reservations.full_name }
                          </label>
                          <div style={{marginRight: '5px'}}>*</div>
                        </div>

                        <input
                          name='reservation[billing_address_attributes][full_name]'
                          placeholder='John Doe'
                          id='billing-full-name'
                          type='text'
                          defaultValue={billingAddress && billingAddress.full_name}
                        />
                      </div>

                      <div className="field-columns">
                        <div className="field">
                          <label>
                            { translations.reservations.phone }
                          </label>
                          <input
                            name='reservation[billing_address_attributes][phone]'
                            id='billing-phone'
                            placeholder='+30 123 456 7890'
                            type="tel"
                            value={ phone }
                            onChange={ event => setPhone(event.target.value.match(/^(\+?\d{0,20})?$/) ? event.target.value : phone) }
                          />
                        </div>

                        <div className="field">
                          <label>
                            { translations.reservations.mobile }
                          </label>
                          <input
                            name='reservation[billing_address_attributes][mobile_phone]'
                            id='billing-mobile_phone'
                            placeholder='+30 123 456 7890'
                            type="tel"
                            value={ mobilePhone }
                            onChange={ event => setMobilePhone(event.target.value.match(/^(\+?\d{0,20})?$/) ? event.target.value : mobilePhone) }
                          />
                        </div>
                      </div>

                      <div className="field-columns">
                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.address }
                            </label>
                            <div style={{marginRight: 5}}>*</div>
                          </div>
                          <input
                            name='reservation[billing_address_attributes][street]'
                            placeholder='1 Example street'
                            id='billing-street'
                            type="text"
                            defaultValue={ billingAddress && billingAddress.street }
                          />
                        </div>

                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.city }
                            </label>
                            <div style={{marginRight: '5px'}}>*</div>
                          </div>
                          <input
                            id="billing-city"
                            name='reservation[billing_address_attributes][city]'
                            placeholder='Athens'
                            type="text"
                            defaultValue={ billingAddress && billingAddress.city }
                          />
                        </div>
                      </div>

                      <div className="field-columns">
                        <div className="field">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.postal_code }
                            </label>
                            <div style={{marginRight: '5px'}}>*</div>
                          </div>
                          <input id="billing-postal_code"
                            name='reservation[billing_address_attributes][postal_code]'
                            placeholder='123 45'
                            type="text"
                            defaultValue={ billingAddress && billingAddress.postal_code}
                          />
                        </div>

                        <div className="field">
                          <SelectInput
                            wrapperClasses='checkout-step'
                            label={ renderCountryLabel() }
                            options={ countries.map(country => ({ ...country, title: country.label })) }
                            currentOption={ currentCountry.value || null }
                            setCurrentOption={ setCurrentCountry }
                          />

                          <input type='hidden' name='reservation[billing_address_attributes][country_numeric_code]' value={ currentCountry.value } />
                        </div>

                        { currentCountry && availableCountryStates.length > 0 &&
                          <div className="field">
                            <SelectInput
                              wrapperClasses='checkout-step'
                              label={ renderStateLabel() }
                              options={ availableCountryStates }
                              currentOption={ currentState?.value ?? null }
                              setCurrentOption={ setCurrentState }
                            />

                            <input type='hidden' name='reservation[billing_address_attributes][state_region_code]' value={ currentState.value } />
                          </div>
                        }

                        <div className="field notes-wrapper">
                          <div className='flex-box items-center'>
                            <label>
                              { translations.reservations.reservation_notes }
                            </label>
                          </div>
                          <textarea
                            name='reservation[notes]'
                            placeholder='e.g need extra blankets, have baby crib in main room'
                            defaultValue={notes}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <PaymentStep
                  appProps={ appProps }
                  showPaymentIframe = { showPaymentIframe }
                  indirect
                  dateRange={ dateRange }
                  stripePublishableKey = { props.stripePublishableKey }
                  orderId = { props.orderId }
                  type = { props.type }
                  paymentIframePath = { props.paymentIframePath }
                  cards = { props.cards }
                  setCurrentCardPath = { props.setCurrentCardPath }
                  cardsPath = { props.cardsPath }
                  addNewCardText = { props.addNewCardText }
                  guestCheckout = { !appProps.currentUserEmail }
                  detailsFormRef={ detailsFormRef }
                  reservationPath={ props.payByLinkPath }
                  chargeAtTimeOfBooking = { !!props.chargeAtTimeOfBooking }
                  billingDetails = { {
                    address: {
                      city: billingAddress.city,
                      country: billingAddress.country_alpha2_code,
                      line1: billingAddress.street,
                      postal_code: billingAddress.postal_code,
                      state: billingAddress.state_name
                    },
                    email: email,
                    name: billingAddress.full_name,
                    phone: billingAddress.mobile_phone || billingAddress.phone
                  } }
                  setShowAgreementsModal={ setShowAgreementsModal }
                  setShowTermsModal={ setShowTermsModal }
                />

                { showAgreementsModal &&
                  <AgreementsModal
                    agreements={ agreements }
                    setShowAgreementsModal={ setShowAgreementsModal }
                  />
                }

                { showTermsModal &&
                  <TermsModal
                    legalTerms={ legalTerms }
                    setShowTermsModal={ setShowTermsModal }
                  />
                }
              </div>
            </div>
          )
        }
      </div>

      { loading && <Loader size='large' fullPage /> }
    </>
  )
}
