import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { StripeCheckoutForm } from "@reactiveonline/frontend_shared_components"

import { formatPriceWithCurrency } from "../../../helpers/utils"
import TokenizedCheckoutForm from "../helpers/TokenizedCheckoutForm"
import { BankDetails } from "@reactiveonline/frontend_shared_components"

export default class PaymentMethod extends React.Component {
  constructor(props) {
    super(props)

    if(this.props.stripePublishableKey) {
      this.stripePromise = loadStripe(this.props.stripePublishableKey)
    }
  }

  handleClick() {
    const { paymentMethod, setSelectedMethod, clearErrors } = this.props
    clearErrors()
    setSelectedMethod(paymentMethod)
  }

  render() {
    const {
      paymentMethod, isSelected, showCost, submitStripeCard, setStripeError,
      completeOrder, stripePaymentIntentClientSecret,
      stripePaymentIntentActionType, setSubmitStripeCard, currency, orderId,
      type, guestCheckout, appProps, stripeSetupIntentClientSecret, stripeSetupIntentActionType
    } = this.props

    const TOKENIZED_PAYMENT_METHODS = ["CardlinkOnePayment", "AlphaBankPayment", "EurobankPayment"]

    return (
      <React.Fragment>
        <div className={`option ${isSelected && "checked_icon selected"}`} onClick={() => { this.handleClick() }}>
          <div className={`name ${paymentMethod.type}`}>
            { paymentMethod.title }
          </div>

          {
            paymentMethod.gross_handling_fee && showCost &&
              <div className="cost">
                { `+ ${formatPriceWithCurrency(paymentMethod.gross_handling_fee.toFixed(2), currency)}` }
              </div>
          }

          <div dangerouslySetInnerHTML={{__html: paymentMethod.description}} className="description"></div>

          { isSelected && paymentMethod.type === 'BankTransfer' &&
            <BankDetails
              appProps={ appProps }
              bankDetails={ paymentMethod.bankDetails }
              hideExtraInfo
            />
          }
        </div>
        {paymentMethod.type === "StripePayment" && isSelected &&
          <Elements stripe={this.stripePromise}>
            <StripeCheckoutForm
              appProps={ appProps }
              submitStripeCard={submitStripeCard}
              setStripeError={setStripeError}
              completeOrder={completeOrder}
              stripePaymentIntentClientSecret={stripePaymentIntentClientSecret}
              stripePaymentIntentActionType={stripePaymentIntentActionType}
              stripeSetupIntentClientSecret={stripeSetupIntentClientSecret}
              stripeSetupIntentActionType={stripeSetupIntentActionType}
              orderId={ orderId }
              type={ this.props.type }
              paymentMethodId = { paymentMethod.id }
              cardsPath = { this.props.cardsPath }
              setIsLoading = { this.props.setIsLoading }
              setCurrentCardPath = { this.props.setCurrentCardPath }
              billingDetails={ this.props.billingDetails }
              payableAlreadyCompleted={ this.props.payableAlreadyCompleted }
            />
          </Elements>
        }
        {TOKENIZED_PAYMENT_METHODS.includes(paymentMethod.type) && isSelected && !guestCheckout &&
          <TokenizedCheckoutForm
            key = { paymentMethod.id }
            paymentMethodId = { paymentMethod.id }
            setCurrentCardPath = { this.props.setCurrentCardPath }
            cardsPath = { this.props.cardsPath }
            setIsLoading = { this.props.setIsLoading }
            orderId={ orderId }
            type={ type }
            addNewCardText={ this.props.addNewCardText }
            setAvailableCards={ this.props.setAvailableCards }
          />
        }


      </React.Fragment>
    )
  }
}

PaymentMethod.defaultProps = {
   clearErrors: () => {},
   paymentMethod: { },
   selectedMethod: { } ,
   updateSelectedPayment: () => {},
   showCost: true,
   stripePublishableKey: '',
   submitStripeCard: () => {},
   stripePaymentIntentClientSecret: '',
   stripeSetupIntentClientSecret: '',
   setStripeError: () => {},
   completeOrder: () => {},
   currency: { }
}
