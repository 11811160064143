import React, { useState, useEffect, useRef, useCallback } from 'react'

export default function PictureUpload({ appProps, imgSrc, setImgSrc, iconClass, cameraId }) {
  const [snapshotTaken, setSnapshotTaken] = useState(false)

  useEffect(() => {
    // Grab elements, create settings, etc.
    let video = document.getElementById(`video-${cameraId}`)

    // Get access to the camera!
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Not adding `{ audio: true }` since we only want video now
      navigator.mediaDevices.getUserMedia({ video: true, audio: false, facingMode: { exact: "user" } }).then(function(stream) {
        //video.src = window.URL.createObjectURL(stream)
        video.srcObject = stream
        video.play()
      })
    } else if(navigator.getUserMedia) { // Standard
      navigator.getUserMedia({ video: true }, function(stream) {
        video.src = stream
        video.play()
      }, errBack)
    } else if(navigator.webkitGetUserMedia) { // WebKit-prefixed
      navigator.webkitGetUserMedia({ video: true }, function(stream){
        video.src = window.webkitURL.createObjectURL(stream)
        video.play()
      }, errBack)
    } else if(navigator.mozGetUserMedia) { // Mozilla-prefixed
      navigator.mozGetUserMedia({ video: true }, function(stream){
        video.srcObject = stream
        video.play()
      }, errBack)
    }

    // navigator.mediaDevices.enumerateDevices().then((devices) => {
    //   console.log("&&&&&&&&", devices)
    // })
  }, [])

  const captureSnapshot = () => {
    let video = document.getElementById(`video-${cameraId}`)
    let canvas = document.getElementById(`canvas-${cameraId}`)
    let context = canvas.getContext('2d')

    let videoWidth = video.offsetWidth;
    let videoHeight = video.offsetHeight;
    context.drawImage(video, 0, 0, videoWidth, videoHeight)

    setSnapshotTaken(true)

    let imageDataUrl = document.querySelector(`#canvas-${cameraId}`).toDataURL('image/jpeg')
    setImgSrc(imageDataUrl)
  }

  const deleteSnapshot = () => {
    let canvas = document.getElementById(`canvas-${cameraId}`)
    let context = canvas.getContext('2d')
    context.clearRect(0, 0, canvas.width, canvas.height)

    setSnapshotTaken(false)
    setImgSrc(null)
  }

  return (
    <React.Fragment>
      <div className='upload-image' style={imgSrc ? {display: 'block'} : {display: 'none'}}>
        <canvas id={`canvas-${cameraId}`} height="250"></canvas>
        <button type='button' className='button plain remove_icon' onClick={ deleteSnapshot }></button >
      </div>

      <div style={!imgSrc ? {display: 'block'} : {display: 'none'}}>
        <video id={`video-${cameraId}`} height="250" autoPlay style={!snapshotTaken ? {display: 'block'} : {display: 'none'}}></video>
        {!snapshotTaken &&
          <button type='button' className={`button ${iconClass}`} style={{ width: 'auto' }} onClick={ captureSnapshot }>
            { appProps.translations.reservations.take_photo }
          </button>
        }
      </div>
    </React.Fragment>
  )
}
