import React, { useState } from 'react'

import SearchReservation from './SearchReservation'
import SendDocuments from './SendDocuments'

export default function SelfCheckin({ appProps, userEmail, reservationNumber, agreementTranslation, placeholdersTranslations, findReservationPath, createCheckinPath }) {
  const [email, setEmail] = useState(userEmail || '')
  const [resNumber, setResNumber] = useState(reservationNumber || '')
  const [reservation, setReservation] = useState(false)

  if(reservation) {
    return (
      <SendDocuments
        appProps={ appProps }
        reservation={reservation}
        email={email}
        agreementTranslation={ agreementTranslation }
        placeholdersTranslations={ placeholdersTranslations }
        reservationNumber={resNumber}
        createCheckinPath={createCheckinPath}
      />
    )
  } else {
    return (
      <SearchReservation
        appProps={ appProps }
        email={email}
        setEmail={setEmail}
        reservationNumber={resNumber}
        setReservationNumber={setResNumber}
        setReservation={setReservation}
        findReservationPath={findReservationPath}
      />
    )
  }
}
