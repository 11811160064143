import React from 'react'
import { Modal } from '@reactiveonline/frontend_shared_components'

export default function AgreementsModal({ agreements, setShowAgreementsModal }) {
  return (
    <Modal
      visible
      closeModal={ () => setShowAgreementsModal(false) }
      mode='large'
    >
      { agreements &&
        <div
          dangerouslySetInnerHTML={{ __html: agreements.description }}
          className='text'
        />
      }
    </Modal>
  )
}
