import React from 'react'

export default function Success({ appProps, reservationNumber }) {
  return (
    <>
      <div className="check-in-screen">
        <div className="heading-wrapper">
          <div className="main-container">
            <h1>
              { appProps.translations.reservations.checked_in }
            </h1>
            <h2>
              { appProps.translations.reservations.successfully_checked_in }: <b>{ reservationNumber }</b>
            </h2>
          </div>
        </div>

        <div className="checkin-wrapper">
          <div className="main-container">
            <h3>
              <b> { appProps.translations.reservations.redirect } <span id="time"></span></b>
            </h3>
          </div>
        </div>
      </div>
    </>
  )
}
