import React, { useState } from 'react'
import PropTypes from 'prop-types'
import RentalListItems from './RentalListItems'
import FilterRentals from './widget/FilterRentals'

export default function Rentals({
  appProps, rentals, rentalListVersion, bookingWidgetPosition, filterRentalsPath
}) {
  const [currentRentals, setCurrentRentals] = useState(rentals)
  const [filtersDates, setFilteredDates]    = useState(null)

  function bookingWidget() {
    return (
      <div className='booking-widget-wrapper'>
        <div className='main-container'>
          <div className='booking-widget'>
            <div className='booking-widget-title'>
              { appProps.translations.rentals.reservation_widget_title}
            </div>
            <div className='booking-widget-subtitle'>
              { appProps.translations.rentals.reservation_widget_subtitle}
            </div>
            <FilterRentals
              appProps={ appProps }
              filterRentalsPath={ filterRentalsPath }
              setRentals={ setCurrentRentals }
              setFilteredDates={ setFilteredDates }
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      { bookingWidgetPosition != 'hide' && bookingWidgetPosition == 'top' &&
        bookingWidget()
      }

      <div className='main-container'>
        <RentalListItems
          appProps={ appProps }
          rentals={ currentRentals }
          rentalListVersion={ rentalListVersion }
          filtersDates={ filtersDates }
        />
      </div>

      { bookingWidgetPosition != 'hide' && bookingWidgetPosition == 'bottom' &&
        bookingWidget()
      }
    </>
  )
}

Rentals.propTypes = {
  rentals: PropTypes.array
}
