import React from "react";

export default function ContactDetails({contact}) {

  if(contact.name === '' && contact.additional_phone === '' &&
     contact.bio === '' && contact.email === '' &&
     contact.phone === '' && contact.website === '') {
    return null
  }

  return(
    <div className='contact-details-wrapper'>
      <div className='contact-heading'>
        { contact.name }
      </div>
      <div className='contact-content'>
        { contact.email &&
          <div className='contact-item email'>
            <a href={`mailto:${ contact.email }`} target='_blank'>
              { contact.email }
            </a>
          </div>
        }
        { contact.phone &&
          <div className='contact-item phone'>
            { !contact.hide_phones && contact.phone }
          </div>
        }
        { contact.additional_phone &&
          <div className='contact-item mobile-phone'>
            { !contact.hide_phones && contact.additional_phone }
          </div>
        }
        { contact.website &&
          <div className='contact-item website'>
            <a href={ contact.website.includes('https://') || contact.website.includes('http://') ? contact.website : `https://${contact.website}` } target='_blank'>
              { contact.website }
            </a>
          </div>
        }
        { contact.bio &&
          <div className='contact-item bio' dangerouslySetInnerHTML={{ __html: contact.bio }}/>
        }
      </div>
    </div>
  )
}
