import React, { useState, useEffect } from 'react'
import RentalListItems from '../../rentals/RentalListItems'

export function RentalList({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [rentals, setRentals] = useState([])
  const settings = item.contentSettings
  const { selectedRentalsPath, translations } = appProps
  function getViewType() {
    let viewType = null
    switch (settings.viewType) {
      case 'classic':
        return 'version_one'
      case 'minimalistic':
        return 'version_two'
      case 'luxury':
        return 'version_three'
      case 'list':
        return 'version_four'
    }
  }

  function getItemsPerRow() {
    switch (settings.itemsPerRow) {
      case '1':
        return 'one_item'
      case '2':
        return 'two_items'
      case '3':
        return 'three_items'
      case '4':
        return 'four_items'
      case '5':
        return 'five_items'
      case '6':
        return 'six_items'
    }
  }

  useEffect( () => {
    if (settings.selectedRentalItems !== 'custom' || (settings.selectedRentalItems === 'custom' && settings.selectedRentals.length)) {
      setLoading(true)

      $.ajax({
        type: 'GET',
        url: selectedRentalsPath,
        data: {
          items: JSON.stringify(settings.selectedRentals),
          selected_rental_items: settings.selectedRentalItems,
          rental_limit: settings.rentalLimit
        },
        dataType: 'json',
        success: res => {
          setRentals(res.items)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  return (
    <div
      style={{  width: '100%' }}
    >
      <div className='builder-rental-list'>
        <RentalListItems
          appProps={ appProps }
          rentals={ rentals }
          rentalListVersion={ getViewType() }
          itemsPerRow={ getItemsPerRow() }
          translations={ translations }
        />
      </div>
    </div>
  )
}
