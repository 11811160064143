import React from 'react'

export default function ReservationHistory({ appProps, reservations }){

  return (
    <>
      <div id="order-progress-modal"></div>
      { reservations.length > 0 ?
        <>
          <div className='heading'>
            <h3>{ appProps.translations.account.reservation_history }</h3>
            <h5>{ appProps.translations.account.reservation_history_subtitle }</h5>
          </div>
          <div className='order_history_wrapper card'>
            <div className='history_headers'>
              <div className='row date'>
                { appProps.translations.reservations.arrival_date_title }
              </div>
              <div className='row date'>
                { appProps.translations.reservations.departure_date_title }
              </div>
              <div className='row number'>
                { appProps.translations.reservations.reservation_number }
              </div>
              <div className='row total'>
                { appProps.translations.reservation_summary.total }
              </div>
              <div className='row delivered'>
                { appProps.translations.reservations.payment_status }
              </div>
              <div className='row'>
                { appProps.translations.reservations.booking_status }
              </div>
              <div className='row actions'/>
            </div>

            { reservations.map( (reservation, index) => (
              <div key={ index } className='history_rows'>
                <div className='row date'>
                  { reservation.arrivalDate }
                </div>

                <div className='row date'>
                  { reservation.departureDate }
                </div>

                <div className='row number'>
                  { reservation.number }
                </div>

                <div className='row total'>
                  { reservation.total }
                </div>

                <div className='row delivered'>
                  { reservation.paymentState }
                </div>

                <div className='row delivered'>
                  { reservation.bookingStatus }
                </div>

                <div className='row actions'>
                  <a className='view view_icon' href={ `/${ appProps.currentLocale }/account/reservations/${ reservation.uid }` }/>
                </div>
              </div>
            ))}
          </div>
        </>
        :
        <div className='no_data orders_icon'>
          <h2>{ appProps.translations.account.no_reservations }</h2>
        </div>
      }
    </>
  )
}
