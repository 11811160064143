import React, { useRef, useState } from 'react'
import { FlashMessage, Loader } from '@reactiveonline/frontend_shared_components'
import TermsAndAgreements from './TermsAndAgreements'

export default function InquiryStep({
  appProps, dateRange, reservationPath, legalTerms, agreements
}) {
  const [loading, setLoading] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [acceptedAgreements, setAcceptedAgreements] = useState(false)

  const flashMessageRef = useRef(null)

  const handleSubmit = () => {
    if (!acceptedTerms || !acceptedAgreements) {
      flashMessageRef.current.show(appProps.translations.flash_messages.accept_terms_error, 'error')
      return
    }

    setLoading(true)
    const fd = new FormData()

    fd.append('start_date', dateRange.start_date)
    fd.append('end_date', dateRange.end_date)
    fd.append('reserve', true)
    fd.append('reservation[booking_status]', 'inquiry')

    Rails.ajax({
      url: reservationPath,
      type: 'PATCH',
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.redirect_url) {
          location.href = res.redirect_url
        } else if (!res.available) {
          flashMessageRef.current.show(res.availableMessage, 'error')
        } else if (!res.validDuration) {
          flashMessageRef.current.show(res.validDurationMessage, 'error')
        } else if (!res.validRestrictions) {
          flashMessageRef.current.show(res.validRestrictions, 'error')
        } else if (!res.validBookingSettings) {
          flashMessageRef.current.show(res.validBookingSettingsMessage, 'error')
        }
      },
      error: res => {
        setLoading(false)
        flashMessageRef.current.show(res.error, 'error')
      }
    })
  }

  return (
    <div className="card checkout-step flex-box flex-column">
      <h3><b>{ appProps.translations.reservations.accept_terms_and_send_inquiry }</b></h3>

      <TermsAndAgreements
        appProps={ appProps }
        legalTerms={ legalTerms }
        agreements={ agreements }
        acceptedAgreements={ acceptedAgreements }
        acceptedTerms={ acceptedTerms }
        setAcceptedAgreements={ setAcceptedAgreements }
        setAcceptedTerms={ setAcceptedTerms }
      />

      <div className='flex-box content-end' style={{ marginTop: 40, width: '100%' }}>
        { loading ?
          <div className='button inverted flex-box items-center content-center'>
            <Loader size='medium'/>
          </div>
          :
          <div onClick={ handleSubmit } className='button'>
            { appProps.translations.reservations.send_inquiry }
          </div>
        }
      </div>

      <FlashMessage ref={ flashMessageRef } />
    </div>
  )
}
