import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'

export default function AvailabilityCheck(props) {
  const { appProps, endDate, onDateChange, startDate, availabilityInfo, bookingSettings, minimumDays } = props
  const [focusedInput, setFocusedInput] = useState(null)
  const [firstBlocked, setFirstBlocked] = useState(null)
  const [minimumStayRule, setMinimumStayRule] = useState(null)
  const smallDevice = window.matchMedia('(max-width: 480px)').matches

  const bookingWindow = bookingSettings && bookingSettings.length > 0 && bookingSettings.find(setting => setting.key == 'booking_window')?.value
  const preparationTime = bookingSettings && bookingSettings.length > 0 && bookingSettings.find(setting => setting.key == 'preparation_time')?.value
  const advancedNotice = bookingSettings && bookingSettings.length > 0 && bookingSettings.find(setting => setting.key == 'advanced_notice')?.value

  useEffect( () => {
    if(startDate && availabilityInfo){
      let minimumRule = minimumDays.find(m => m.day == startDate.format('dddd').toLowerCase()) || minimumDays.find(m => m.day == 'all')

      setMinimumStayRule(minimumRule && minimumRule.minimumDays)
    }
  }, [startDate])

  const renderDayContents = (day, date) => {
    if(availabilityInfo && !endDate && focusedInput != 'startDate' && ((new Date(day).setHours(0,0,0,0)) > (new Date(startDate).setHours(0,0,0,0))) && ((new Date(day).setHours(0,0,0,0)) < (new Date(new Date(startDate).setDate(new Date(startDate).getDate() + parseInt(minimumStayRule))).setHours(0,0,0,0)))){
      return <span data-title={`${minimumStayRule} ${appProps.translations.rentals[minimumStayRule == 1 ? 'minimum_night' : 'minimum_nights']}`}>{ day.format('D') }</span>
    } else {
      return <span>{ day.format('D') }</span>
    }
  }

  function handleDateChange(dates) {
    if(dates.startDate && !dates.endDate) {
      setFirstBlocked(availabilityInfo.find(d => new Date(d) > dates.startDate))
      onDateChange(dates)
    } else if(dates.startDate && dates.endDate) {
        if(availabilityInfo.find(d => (new Date(d) > dates.startDate) && new Date(d) < dates.endDate)){
          onDateChange({ startDate: dates.endDate, endDate: null })
        } else {
          onDateChange(dates)
        }

        setFirstBlocked(null)
    }
  }

  return (
    <div>
      <DateRangePicker
        displayFormat={ () => 'DD MMM YYYY' }
        isDayBlocked={ (day) => {
          return (
            availabilityInfo && (
              availabilityInfo.find(d => d == day.format('YYYY-MM-DD')) ||
              (firstBlocked && ((new Date(day)) > (new Date(firstBlocked)))) ||
              (advancedNotice && ((new Date(day).setHours(0,0,0,0)) < (new Date(new Date().setDate(new Date().getDate() + parseInt(advancedNotice))).setHours(0,0,0,0)))) ||
              ((new Date(day).setHours(0,0,0,0)) >= (new Date(new Date().setDate(new Date().getDate() + (bookingWindow ? parseInt(bookingWindow) : 365))).setHours(0,0,0,0))) ||
              (preparationTime && preparationTime == 2 && availabilityInfo.find(d => d == moment(new Date(new Date(day).setDate(new Date(day).getDate()-1))).format('YYYY-MM-DD')))
            )
          )
        }}
        endDate={ endDate }
        endDateId='endDate'
        endDatePlaceholderText={ appProps.translations.reservations.departure_date_title }
        focusedInput={focusedInput}
        onDatesChange={availabilityInfo ? handleDateChange : onDateChange}
        minimumNights={ availabilityInfo ? minimumStayRule : 0 }
        renderDayContents={renderDayContents}
        onFocusChange={setFocusedInput}
        orientation={smallDevice ? 'vertical' : 'horizontal'}
        readOnly={ true }
        required={ true }
        noBorder={ true }
        startDate={ startDate }
        startDateId='startDate'
        startDatePlaceholderText={ appProps.translations.reservations.arrival_date_title }
        firstDayOfWeek={ 1 }
        hideKeyboardShortcutsPanel
      />
    </div>
  )
}

AvailabilityCheck.propTypes = {
  endDate: PropTypes.object,
  onDateChange: PropTypes.func,
  startDate: PropTypes.object,
}
