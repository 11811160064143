import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import PictureUpload from './PictureUpload'
import Agreements from '../reservations/Agreements'
import { NO_IMAGE_SOURCE } from '../rentals/constants'
import { FlashMessage, Loader } from '@reactiveonline/frontend_shared_components'

export default function SendDocuments({ appProps, reservation, email, reservationNumber, findReservationPath, agreementTranslation, placeholdersTranslations }) {
  const [canTakeSnapshot, setCanTakeSnapshot] = useState(false)
  const [initiateSnapshot, setInitiateSnapshot] = useState(true)

  const [passportDoc, setPassportDoc] = useState('')
  const [facePicture, setFacePicture] = useState('')

  const [loading, setLoading] = useState(false)

  const flashMessageRef = useRef(null)

  function checkIn() {
    setLoading(true)

    let fd = new FormData()
    fd.append('email', email)
    fd.append('reservation[number]', reservationNumber)
    fd.append('reservation[passport]', passportDoc)
    fd.append('reservation[face]', facePicture)

    Rails.ajax({
      type: 'POST',
      url: findReservationPath,
      dataType: 'json',
      data: fd,
      success: res => {
        if(res.location) {
          window.location.href = res.location
        }
      },
      error: res => {
        flashMessageRef.current.show(res.errors, 'error')
      },
      complete: () => setLoading(false)
    })
  }

  return (
    <>
      <div>
        <div>
          { reservation.checkInStatus ?
            <>
              <div className="heading-wrapper">
                <div className='main-container'>
                  <h1>{ appProps.translations.reservations.already_checked_in }</h1>
                  <h4>{ appProps.translations.reservations.reservation_details_below }</h4>
                </div>
              </div>
            </>
            :
            <>
              <div className="heading-wrapper">
                <div className='main-container'>
                  <h1>{ appProps.translations.reservations.check_in }</h1>
                  <h4>{ appProps.translations.reservations.review_and_upload }</h4>
                </div>
              </div>
              <div className='main-container'>
                <div className='upload-wrapper card'>
                  <div className='upload'>
                    <label><b>{ appProps.translations.reservations.document_picture }</b></label>
                    { initiateSnapshot &&
                      <button style={{ marginTop: 20 }} className='button inverted' onClick={ ()=> { setCanTakeSnapshot(true); setInitiateSnapshot(false) } }>
                        { appProps.translations.reservations.open_camera }
                      </button>
                    }
                    { canTakeSnapshot &&
                      <PictureUpload
                        cameraId={1}
                        imgSrc={ passportDoc }
                        setImgSrc={ (uploadedImg) => setPassportDoc(uploadedImg) }
                        iconClass={ 'custom-document-upload' }
                      />
                    }
                  </div>
                  <div className='upload'>
                    <label><b>{ appProps.translations.reservations.person_picture }</b></label>
                    { initiateSnapshot &&
                      <button style={{ marginTop: 20 }} className='button inverted' onClick={ ()=> { setCanTakeSnapshot(true); setInitiateSnapshot(false) } }>
                        { appProps.translations.reservations.open_camera }
                      </button>
                    }
                    { canTakeSnapshot &&
                      <PictureUpload
                        cameraId={2}
                        imgSrc={ facePicture }
                        setImgSrc={ (uploadedImg) => setFacePicture(uploadedImg) }
                        iconClass={ 'custom-picture-upload' }
                      />
                    }
                  </div>
                </div>
              </div>
              <div className='main-container'>
                <div className='finalize-check-in-wrapper'>
                  { loading ? <Loader size='medium' /> :
                    passportDoc && facePicture &&
                    <div className='finalize-check-in'>
                      <div className='terms'>By clicking the button below you agree to our terms of service and privacy policy.</div>
                      <div className='button' onClick={checkIn} >
                        { appProps.translations.reservations.finalize_check_in }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>
          }
        </div>
      </div>

      <div className="checkouts-wrapper order-complete checkin-wrapper">
        <div className='main-container'>
          <div className="order-details card">
            <div className="order-detail-block">
              <div className="order-detail-block-title">
                { appProps.translations.reservations.reservation_number} : { reservation.number }
              </div>
              <div className="order-detail-block-description">
                { reservation.completed_at }
              </div>

              { reservation.checkInAndOutTime &&
                <div className="order-detail-block-description" style={{ marginTop: 20 }}>
                  <div>
                    <b>{ appProps.translations.reservations.check_in }:</b>
                    { reservation.checkInTime }
                  </div>
                  <div>
                    <b>{ appProps.translations.reservations.check_out }:</b>
                    { reservation.checkOutTime }
                  </div>
                </div>
              }
            </div>

            <div className="order-detail-block">
              <div className="order-detail-block-title">
                { appProps.translations.reservations.payment_status} : { reservation.paymentStatus }
              </div>
              <div className="order-detail-block-description">
                { appProps.translations.reservation_summary.price_all_nights } : { reservation.priceAllNights }<br/>
                { appProps.translations.reservation_summary.price_per_night } : { reservation.pricePerNight } <br/>

                { reservation.addonsShortStay &&
                  `${appProps.translations.reservation_summary.short_stay_addon} : ${reservation.addonsShortStay}`
                }

                { reservation.addonsExtraGuests &&
                  `${appProps.translations.reservation_summary.extra_guests_addon} : ${reservation.addonsExtraGuests}`
                }

                <b>{appProps.translations.reservations.reservation_total } : { reservation.amount }</b>
              </div>
            </div>

            <div className="order-detail-block">
              <div className="order-detail-block-title">
                { appProps.translations.reservations.billing_info }
              </div>
              <div className="order-detail-block-description">
                { reservation.billingAddress }
              </div>
            </div>

            <div className="order-detail-block">
              <div className="order-detail-block-title">
                { appProps.translations.reservations.reservation_notes }
              </div>
              <div className="order-detail-block-description">
                { reservation.notes }
              </div>
            </div>

          </div>

          <div className='rental card' style={{ width: '100%' }}>
            <div className='rental-image'>
              <img style={{ height: 200 }} src={ reservation.rentalImage ? reservation.rentalImage : NO_IMAGE_SOURCE } alt='' />
            </div>
            <div className='rental-details'>
              <div className='rental-detail name'>
                <h3><b>{/* reservation.rentalName */}</b></h3>
              </div>
              { reservation.rentalDescription &&
                <div className='rental-detail description' dangerouslySetInnerHTML={{__html: reservation.rentalDescription}}></div>
              }
            </div>
          </div>

          { reservation.arrangements && reservation.arrangements.length > 0 &&
            <div>
              <div className='rental-detail arrangements card'>
                <div>
                  <b>{ appProps.translations.rentals.sleep_arrangements }</b>
                </div>
                <div className="details-list">
                  { reservation.arrangements.map( (arrangement, index) => {
                    return (
                      <div key={index} className='detail'>
                        <div dangerouslySetInnerHTML={{__html: arrangement.presentation}}></div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }

          { reservation.rooms && reservation.rooms.length > 0 &&
            <div>
              <div className='rental-detail rooms card'>
                <div>
                  <b>{ appProps.translations.rentals.amenities }</b>
                </div>
                <div className="details-list">
                  { reservation.rooms.map( (room, index) => {
                    return (
                      <div key={index} className='detail'>
                        <div dangerouslySetInnerHTML={{__html: room.presentation}}></div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }

          { reservation.amenities && reservation.amenities.length > 0 &&
            <div>
              <div className='rental-detail amenities card'>
                <div>
                  <b>{ appProps.translations.rentals.facilities }</b>
                </div>
                <div className="details-list">
                  { reservation.amenities.map( (amenity, index) => {
                    return (
                      <div key={index} className='detail'>
                        <div dangerouslySetInnerHTML={{__html: amenity.presentation}}></div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }

          { reservation.agreement && reservation.placeholders &&
            <div>
              <div className="reservation-agreements">
                <Agreements
                  reservationAgreement={ reservation.agreement }
                  placeholders={ reservation.placeholders }
                  agreementTranslation={ agreementTranslation }
                  placeholdersTranslations={ placeholdersTranslations }
                />
              </div>
            </div>
          }
        </div>
      </div>

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}
