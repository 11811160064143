import React from 'react'

const InformationBox = ({information, arrangements, rooms}) => {
  return(
    <div className='featured-amenities'>
      <div className='room-details-row'>
        { information.area &&
          <div className='room-details-item flex-box'>
            <div className='room-area area_icon'>
              {`${information.area}`}
            </div>
            { information.available_units > 0 &&
              <div className='available-units room_icon' style={{ marginLeft: 10 }}>
                { information.available_units }
              </div>
            }
          </div>
        }
        <div className='room-details-item'>
          <div className='arrangements'>
            {
              arrangements.map((arrangement, i) => {
                return(
                  <div key={i} className='arrangement'>
                    {`${arrangement.quantity} ${arrangement.presentation}`}
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='room-details-item'>
          <div className='room-details'>
            {
              rooms.map((room, i) => {
                return(
                  <div key={i} className='room-detail'>
                    {`${room.quantity} ${room.presentation}`}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default InformationBox
