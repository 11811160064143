import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { paidAmount } from '../../admin/policies/shared/helpers'

const Summary = ({ appProps, currentStep, currentRental, cost, isLoading }) => {

  if(!currentRental){
    return (
      <div className='summary-container card'>
        <div className='summary-details'>
          <div className='summary-detail heading'>
            <h3>
              { appProps.translations.reservation_summary.title }
            </h3>
          </div>
          <div className='summary-detail'>
            { appProps.translations.reservation_summary.no_rental_selected }
          </div>
        </div>
      </div>
    )
  }

  const { images = [], name = '' } = currentRental

  const loader = <Loader size='medium' />

  return(
    <div className='summary-container card'>
      <div className='top-section'>
        <div className='summary-details'>
          <div className='summary-detail heading'>
            <h3>
              { appProps.translations.reservation_summary.title }
            </h3>
          </div>
          { images.length > 0 &&
            <div className='summary-detail image'>
              <img src={ images[0]?.thumbnail } alt='thumbnail'/>
            </div>
          }
        </div>
      </div>

      <div className='bottom-section'>
        <div className='rental-info'>
          <div className='summary-details'>
            <div className='summary-detail name'>
              { name }
            </div>
            <div className='summary-detail'>
              <div className='label'>
                { appProps.translations.reservation_summary.price_all_nights }
              </div>
              <div className='value'>{ isLoading ? loader : cost.base_amount }</div>
            </div>
            <div className='summary-detail'>
              <div className='label'>
                { appProps.translations.reservation_summary.price_per_night }
              </div>
              <div className='value'>{ isLoading ? loader : cost.price_per_night }</div>
            </div>
            { cost.addon_costs.short_stay &&
              <div className='summary-detail'>
                <div className='label'>
                  { appProps.translations.reservation_summary.short_stay_addon }
                </div>
                <div className='value'>{ isLoading ? loader : cost.addon_costs.short_stay }</div>
              </div>
            }

            { cost.addon_costs.extra_guests &&
              <div className='summary-detail'>
                <div className='label'>
                  { appProps.translations.reservation_summary.extra_guests_addon }
                </div>
                <div className='value'>{ isLoading ? loader : cost.addon_costs.extra_guests }</div>
              </div>
            }
          </div>
        </div>

        <div className='resrvation-info'>
          { currentRental.has_policy &&
            <div className="summary-details">
              <div className='summary-detail name'>
                { currentRental.policy.payment.description }
              </div>
              { cost.payment_steps.filter(el => el.raw_amount != 0).map((item, i) => (
                  <div key={ i } className="summary-detail">
                    <div className="label">
                      { item.description }
                    </div>
                    <div className="value">
                      { item.amount }
                    </div>
                  </div>
                ))
              }
            </div>
          }

          { currentRental.has_policy && currentRental.policy.warranty.protection_type === "enabled" &&
            <div className="summary-details">
              <div className='summary-detail name'>
                { currentRental.policy.warranty.description }
              </div>
              <div className="summary-detail">
                <div className="label" style={{paddingRight: 30}}>
                  { currentRental.policy.warranty.protection_ends }
                </div>
                <div className="value">
                  { cost.warranty }
                </div>
              </div>
            </div>
          }

          <div className='summary-details'>
            <div className='summary-detail name'>
              { appProps.translations.reservation_summary.taxes_and_fees }
            </div>

            { isLoading ? <div className='summary-detail'>{ loader }</div> :
              cost.fees_and_taxes.map( (item, index) => (
                <div key={ index } className='summary-detail'>
                  <div className='label'>
                    { item.title || item.type === 'historic_sales_tax' && appProps.translations.reservation_summary.vat }
                  </div>
                  <div className='value'>
                    { item.amount }
                  </div>
                </div>
              ))
            }
          </div>

          { cost.entity_addons.length > 0 &&
            <div className='summary-details'>
              <div className='summary-detail name'>
                { appProps.translations.reservation_summary.entity_addons }
              </div>

              { isLoading ? <div className='summary-detail'>{ loader }</div>
                : cost.entity_addons.map((item, index) => (
                    <div key={ index } className='summary-detail'>
                      <div className='label'>
                        { item.title }
                      </div>
                      <div className='value'>
                        { item.amount }
                      </div>
                    </div>
                  ))
              }
            </div>
          }

          <div className='summary-details'>
            <div className='summary-detail'>
              <div className='label long'>
                { appProps.translations.reservation_summary.total }
              </div>
              <div className='value'>{ isLoading ? loader : cost.total_amount }</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Summary

Summary.propTypes = {
  currentRental: PropTypes.object,
  currentStep: PropTypes.number,
  reservationPath: PropTypes.string
}
