import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import { emailValidation } from '@reactiveonline/reactive_admin_shared-packages/utils'
import ErrorMessage from '../shared/ErrorMessage'

export default function SearchReservation({ appProps, email, setEmail, reservationNumber, setReservationNumber, setReservation, findReservationPath }) {
  const [searchError, setSearchError] = useState(false)
  const [loading, setLoading] = useState(false)

  function handleClick() {
    setLoading(true)
    setSearchError(false)

    let formErrors = document.querySelectorAll('[id$="-error-message"]')

    for(let i=0; i<formErrors.length; i++) {
      formErrors[i].remove()
    }

    let formInputs = document.querySelectorAll('[id$="-input"]')
    for(let i=0; i<formInputs.length; i++) {
      formInputs[i].style.borderColor = 'initial'
    }

    if(!emailValidation(email) || email.length === 0) {
      ErrorMessage("email-input", "red", "email-error-message", appProps.translations.reservations.email_not_valid, "red", {position: 'absolute'})
      setLoading(false)
    }

    if(reservationNumber.length === 0){
      ErrorMessage("reservation-number-input", "red", "reservation-number-error-message", appProps.translations.reservations.reservation_number_not_filled, "red", {position: 'absolute'})
      setLoading(false)
    }

    if(emailValidation(email) && reservationNumber.length) {
      findReservation()
    }
  }

  function findReservation() {
    Rails.ajax({
      type: 'GET',
      url: `${findReservationPath}?email=${email}&reservation_number=${reservationNumber}`,
      dataType: 'json',
      success: res => {
        setReservation(res.reservation)
        setLoading(false)
      },
      error: res => {
        setSearchError(true)
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className="heading-wrapper">
        <div className='main-container'>
          <h1>{ appProps.translations.reservations.check_in }</h1>
          <h4>{ appProps.translations.reservations.check_in_subtitle }</h4>
        </div>
      </div>

      <div className='main-container'>
        { searchError &&
          <div style={{ color: 'red', paddingBottom: 20 }}>
            { appProps.translations.reservations.reservation_not_found }
          </div>
        }

        <div className='checkin-wrapper'>
          <div className='fields flex-box items-center'>
            <div className='field flex-1'>
              <input
                id="reservation-number-input"
                type="text"
                value={reservationNumber}
                name="reservation number"
                placeholder={appProps.translations.reservations.reservation_number}
                onChange={ (event) => setReservationNumber(event.target.value) }
              />
            </div>
            <div className='field flex-1'>
              <input
                id="email-input"
                type="text"
                value={email}
                name="email"
                placeholder={appProps.translations.reservations.email}
                onChange={ (event) => setEmail(event.target.value) }
              />
            </div>
            <div className='field flex-1 button-wrapper'>
              { loading ?
                <div className="button inverted">
                  <Loader size='medium' />
                </div>
                :
                <div className="button" onClick={ ()=> handleClick() }>
                  { appProps.translations.reservations.check_in }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
