import React, { Fragment } from 'react'
import { NO_IMAGE_SOURCE } from './constants'

export default function RentalListItem(props) {
  const {
    arrangements,
    base_rate,
    image,
    information,
    name,
    rooms
  } = props.rental

  const arrangementsSection = arrangements.length > 0 &&
    <div>
      <i className="fad fa-bed-alt"></i>
      {`${arrangements[0].quantity} ${arrangements[0].presentation}`}
    </div>

  const informationSection = information.area &&
    <div className='flex-box'>
      <div>
        <i className="fal fa-vector-square"></i>
        {information.area}
      </div>
      { information.available_units > 0 &&
        <div style={{ marginLeft: 10 }}>
          <i className="fal fa-door-open"></i>
          {information.available_units}
        </div>
      }
    </div>

  const roomsSection = rooms.length > 0 &&
    <div>
      <i className="far fa-bed"></i>
      {`${rooms[0].quantity} ${rooms[0].presentation}`}
    </div>

  return (
    <Fragment>
      <div className="rental-image">
        <img src={ image ? image : NO_IMAGE_SOURCE } loading='lazy'/>
      </div>
      { props.rentalListVersion == 'version_two' &&
        <div className='rental-bottom-title'>
          {name}
        </div>
      }
      <div className="rental-details">
        <div className="rental-name">
          {name}
        </div>
        <div className="rental-base-rate">
          {base_rate && base_rate.price_per_night}
        </div>
        <div className="bottom-rental-details">
          <div className="rental-amenities">
            { informationSection }
            { arrangementsSection }
            { roomsSection }
          </div>
        </div>
      </div>
    </Fragment>
  )
}
