import React from 'react'

export default function GuestSelector({ appProps, guests, setGuests }){

  return(
    <div className='guest-selector-wrapper flex-box items-center content-space-between'>
      <div>
        { appProps.translations.rentals.guests }:
      </div>
      <div className='guest-selector-controls flex-box items-center'>
        <div
          className='guest-selector-decrement icon_minus button plain'
          onClick={ () => {
            if (guests > 1) {
              setGuests(guests - 1)
            }
          }}
        ></div>
        <input readOnly className='number-of-guests-indicator' value={guests}/>
        <div
          className='guest-selector-increment icon_plus button plain'
          onClick={ () => setGuests(guests + 1) }
        ></div>
      </div>
    </div>
  )
}
