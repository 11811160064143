import React from "react"
import MiniCart from "./MiniCart"
import { AuthorizableLink, LogoutLink } from '@reactiveonline/frontend_shared_components'

export default function HeaderIcons({ appProps }) {
  return (
    <div className="header-icons-container">
      <ul>
        { appProps.currentReservationPath &&
          <li className="cart-dropmenu mini_cart">
            <div id="mini-cart-ajax-container">
              <MiniCart
                appProps={ appProps }
              />
            </div>
          </li>
        }
        <li className="account">
          {appProps.userFormLoginType ?
            <AuthorizableLink
              appProps={ appProps }
              linkClass="account_icon my-account"
              linkRef={ appProps.accountPath }
            /> :
            <a className="account_icon my-account" href={ appProps.accountPath }></a>
          }
        </li>
        <li className="logout">
          { appProps.user &&
            <LogoutLink
              logoutPath={ appProps.logoutPath }
              redirectAfterAuthPath={ appProps.redirectAfterAuthPath }
            />
          }
        </li>
      </ul>
    </div>
  )
}
