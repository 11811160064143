import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import ReservationBox from './ReservationBox'
import { AuthorizableModal, FlashMessage } from "@reactiveonline/frontend_shared_components"
import { toURLEncoded } from 'helpers/utils'

export default function CheckAndReserve({
  appProps, pricePerNight, checkOrReservePath, reserveNowPath, rentalId, currentLocale, translations, availabilityInfo, bookingSettings, minimumDays, slug
}) {
  const [available, setAvailable] = useState(false)
  const [calculatedPrice, setCalculatedPrice] = useState('')
  const [taxText, setTaxText] = useState('')
  const [guests, setGuests] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showAuthorize, setShowAuthorize] = useState(false)

  const flashMessageRef = useRef(null)

  useEffect(()=> {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('filter_start_date') && searchParams.has('filter_end_date') && searchParams.has('filter_guests')) {
      setStartDate( moment(new Date(searchParams.get('filter_start_date'))) )
      setEndDate( moment(new Date(searchParams.get('filter_end_date'))) )
      setGuests( searchParams.get('filter_guests') )
    }
  },[])

  useEffect( () => {
    setAvailable(false)
  }, [startDate, endDate, guests])

  function handleCheckOrReserve() {
    if (typeof(endDate) == 'undefined' || typeof(startDate) == 'undefined' || guests < 1) return
    if (!appProps.user && available) {
      setShowAuthorize(true)
      return
    }

    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: checkOrReservePath,
      dataType: 'json',
      data: toURLEncoded({
        guests: guests,
        end_date: endDate.format('YYYY-MM-DD'),
        rental_uid: rentalId,
        start_date: startDate.format('YYYY-MM-DD'),
        reserve: available
      }),
      success: result => {
        if (!available || !result.reserve) {
          setAvailable(result.available && result.validDuration && result.validRestrictions && result.validBookingSettings)
          setCalculatedPrice(result.calculatedPrice)
          setTaxText(result.taxText)

          if (!result.available) {
            flashMessageRef.current.show(result.availableMessage, 'error')
          } else if (!result.validDuration) {
            flashMessageRef.current.show(result.validDurationMessage, 'error')
          } else if (!result.validRestrictions) {
            flashMessageRef.current.show(result.validRestrictionsMessage, 'error')
          } else if (!result.validBookingSettings) {
            flashMessageRef.current.show(result.validBookingSettingsMessage, 'error')
          }

          setLoading(false)

        } else {
          if (result.location) {
            window.location.href = result.location
          } else if (result.errors) {
            flashMessageRef.current.show(result.errors, 'error')
          }
        }
      },
      error: (result) => {}
    })
  }

  return (
    <>
      <ReservationBox
        appProps={ appProps }
        guests={ guests }
        setGuests={ setGuests }
        startDate={ startDate }
        setStartDate={ setStartDate }
        endDate={ endDate }
        setEndDate={ setEndDate }
        pricePerNight={ pricePerNight }
        calculatedPrice={ calculatedPrice }
        taxText={ taxText }
        buttonAction={ handleCheckOrReserve }
        buttonText={ appProps.translations.rentals[available ? 'book_now' : 'check_availability'] }
        loading={ loading }
        availabilityInfo={ availabilityInfo }
        bookingSettings={ bookingSettings }
        minimumDays={ minimumDays }
      />

      { showAuthorize &&
        <AuthorizableModal
          appProps={ appProps }
          authorizableScreen={ 'login' }
        />
      }

      <FlashMessage ref={ flashMessageRef } />
    </>
  )
}
