import React from 'react'
import moment from 'moment'
import { Loader } from "@reactiveonline/frontend_shared_components"
import PriceDisplay from './PriceDisplay'
import AvailabilityCheck from './AvailabilityCheck'
import GuestSelector from './GuestSelector'

export default function ReservationBox({
  appProps, guests, setGuests, startDate, setStartDate, endDate, setEndDate, pricePerNight,
  calculatedPrice, taxText, buttonAction, buttonText, loading, availabilityInfo, bookingSettings, minimumDays
}) {
  moment.locale(appProps.currentLocale)

  function onDateChange({ startDate, endDate }) {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return(
    <>
      <PriceDisplay
        pricePerNight={ pricePerNight }
        totalPrice={ calculatedPrice }
        taxText={ taxText }
      />

      <div className='reservation-box'>
        <AvailabilityCheck
          appProps={ appProps }
          startDate={ startDate }
          endDate={ endDate }
          onDateChange={ onDateChange }
          availabilityInfo={ availabilityInfo }
          bookingSettings={ bookingSettings }
          minimumDays={ minimumDays }
        />
        <GuestSelector
          appProps={ appProps }
          guests={ guests }
          setGuests={ setGuests }
        />

        { loading ?
          <div className='button-wrapper flex-box content-end items-center' style={{ position: 'relative' }}>
            <Loader
              size='medium'
              position='absolute'
            />
          </div>
        :
          <div className='button-wrapper flex-box content-end'>
            <button
              className={`button ${!(startDate && endDate) ? 'btn-disabled' : ''}`}
              onClick={ buttonAction }
              disabled={ !(startDate && endDate) }>
              { buttonText }
            </button>
          </div>
        }
      </div>
    </>
  )
}
