import React from "react"
import PropTypes from 'prop-types'
import moment from 'moment'

function compare( a, b ) {
  if ( a.day_index < b.day_index ){
    return -1;
  }
  if ( a.day_index > b.day_index ){
    return 1;
  }
  return 0;
}

const WeekdayPrices = ({ appProps, prices }) => {
  moment.locale(appProps.currentLocale)
  const daysRow = []
  const pricesRow = []

  prices.sort( compare ).forEach(price => {
    daysRow.push(
      <div className='table-head-item' key={ price.day_index }>
        { moment().isoWeekday(price.day_index + 1).format('ddd') }
      </div>
    )
    pricesRow.push(
      <div className='table-body-item' key={ price.day_index }>
        { price.amount }
      </div>
    )
  })

  return(
    <div className='weekday-prices-table'>
      <div className='table-head'>
        <div className='table-head-row'>
          {daysRow}
        </div>
      </div>
      <div className='table-body'>
        <div className='table-body-row'>
          {pricesRow}
        </div>
      </div>
    </div>
  )
}

WeekdayPrices.propTypes = {
  prices: PropTypes.array
}

export default WeekdayPrices
