import React, { useEffect, useState } from 'react'
import { IncompleteStep } from './Steps'
import { SelectInput, Loader } from '@reactiveonline/frontend_shared_components'

export default function AddonsStep({
  appProps, availableAddons, currentStep, isLoading, onNextStep, onSetCurrent, onUpdateAddons, selectedAddons
}) {
  const handleAddonSelect = addon => {
    const addonIndex = selectedAddons.findIndex(item => item.id === addon.id)
    let updatedAddons = selectedAddons

    if (addonIndex > -1) {
      updatedAddons = [
        ...selectedAddons.slice(0, addonIndex),
        ...selectedAddons.slice(addonIndex + 1)
      ]
    } else {
      updatedAddons = [...selectedAddons, addon]
    }

    onUpdateAddons(updatedAddons)
  }

  const handleQuantityUpdate = (addon, quantity) => {
    const addonIndex = selectedAddons.findIndex(item => item.id === addon.id)
    const updatedAddons = [
      ...selectedAddons.slice(0, addonIndex),
      { ...addon, quantity: quantity },
      ...selectedAddons.slice(addonIndex + 1)
    ]

    onUpdateAddons(updatedAddons)
  }

  if (currentStep < 3) {
    return <IncompleteStep stepTitle={ appProps.translations.reservations.reservation_addons } />
  }

  if (currentStep > 3) {
    return(
      <div className='card checkout-selected-step flex-box items-center checked_icon' onClick={ onSetCurrent }>
        <div className='edit_icon'></div>
        <div className='rental-details'>
          <div className='details'>
            <div><b>{ appProps.translations.reservations.reservation_addons }</b></div>
            { selectedAddons.length > 0
              ? <>
                  { selectedAddons.reduce((acc, curr) => acc += `${ curr.title }, `, '').slice(0, -2) }
                </>
              : <div className='detail'>-</div>
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='card flex-box flex-column'>
      <h3>
        <b>{ appProps.translations.reservations.reservation_addons }</b>
      </h3>

      <div className='step-editor flex-box flex-wrap payment-methods'>
        { availableAddons.map((addon, index) => (
          <div
            key={ index }
            className={ `option ${ selectedAddons.find(item => item.id === addon.id) && "checked_icon selected" }` }
            onClick={ () => handleAddonSelect(addon) }
          >
            <div className='name'>
              { addon.title }
            </div>

            { addon.description &&
              <div
                className='description'
                dangerouslySetInnerHTML={{ __html: addon.description }}
              />
            }

            { addon.chargeType === 'per_quantity' && selectedAddons.find(item => item.id === addon.id) &&
              <div
                onClick={ e =>  e.stopPropagation() }
                style={{ width: '100px' }}
              >
                <SelectInput
                  label={ appProps.translations.entity_addons.quantity }
                  options={ [...Array(addon.maximumQuantity).keys()].map(el => ({ value: el + 1, title: el + 1 }) )}
                  currentOption={ selectedAddons.find(item => item.id === addon.id).quantity || 1 }
                  setCurrentOption={ quantity => handleQuantityUpdate(addon, quantity.value) }
                />
              </div>
            }

            <div>
              { !!addon.percentage
                ? <>
                    { `${ addon.symbolizedTotalAmount } ${ appProps.translations.entity_addons.single_charge } ${ appProps.translations.entity_addons.per_stay }` }
                  </>
                : <>
                    { `${ addon.symbolizedTotalAmount } ${ appProps.translations.entity_addons[addon.chargeType] } ${ appProps.translations.entity_addons[addon.frequency] }` }
                  </>
              }
            </div>
          </div>
        ))}
      </div>

      <div className='button-wrapper flex-box content-end' style={{ marginTop: 40 }}>
        { isLoading ?
          <div className='button inverted flex-box items-center content-center'>
            <Loader size='medium'/>
          </div>
          :
          <div className='button' onClick={ onNextStep }>{ appProps.translations.reservations.next_step }</div>
        }
      </div>

    </div>
  )
}
