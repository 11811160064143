import React from "react"

export default function ErrorMessage(inputId, inputBorderColor, errorId, message, errorColor){

  const input = document.getElementById(inputId)
  input.style.borderColor = `${ inputBorderColor } !important`

  let errorMessage = document.createElement("div")
  errorMessage.id = errorId
  errorMessage.innerHTML = message
  errorMessage.style.color = errorColor

  input.parentNode.insertBefore(errorMessage, input.nextSibling)

  return null
}
