import React from "react"
import { initiateCheckoutAnalytics } from './../../helpers/analytics'

export default function MiniCart({ appProps }) {
  return (
    <a href="" className="reservation_icon" onClick={(e) => {
      e.preventDefault()
      location.href = appProps.currentReservationPath
    }}></a>
  )
}
