import React from 'react'
import { Modal } from '@reactiveonline/frontend_shared_components'

export default function TermsModal({ legalTerms, setShowTermsModal }) {
  return (
    <Modal
      visible
      closeModal={ () => setShowTermsModal(false) }
      mode='large'
    >
      { legalTerms &&
        <div
          dangerouslySetInnerHTML={{ __html: legalTerms }}
          className='text'
        />
      }
    </Modal>
  )
}
