import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga4"
import cookie from "react-cookies"
// import { getTranslatableField } from "./utils"

const marketingCookies  = cookie.load("cookieSettings") && cookie.load("cookieSettings").marketingCookies === true
const statisticsCookies = cookie.load("cookieSettings") && cookie.load("cookieSettings").statisticsCookies === true

export function loadrentalListAnalytics(appProps) {
  // if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
  //   ReactPixel.trackCustom('ViewContent', {
  //     name: [ `${ rentals.map((rental) => `${rental.title}`)}`],
  //   })
  // }
}

export function loadrentalViewAnalytics(appProps) {
  // if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
  //   ReactPixel.trackCustom('ViewContent', {
  //     name:     getTranslatableField(rental, 'title', appProps.currentLocale, false),
  //     value:    rental.totalPrice,
  //     currency: appProps.currency
  //   })
  // }
}

export function initiateCheckoutAnalytics(appProps, reservation) {
  // if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
  //   ReactPixel.trackCustom('InitiateCheckout', {
  //     content_name: 'Initiated Checkout',
  //     value:    `${reservation.totalValue}`,
  //     items:    `${reservation.lineItems.length}`,
  //     rentals: `${reservation && reservation.lineItems.map((p) => {
  //         return `${p.sellable.title}, price: ${p.totalGross}, category: ${p.sellable.prototypeName ? p.sellable.prototypeName : ''}`
  //       })
  //     }`,
  //     orderNo:  `${reservation.number}`,
  //     currency: `${appProps.currency}`,
  //   });
  // }
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ReactGA.event({
  //     category: 'Click Checkout',
  //     action: 'Initiated Checkout',
  //     value: `${reservation.totalValue}`, // optional, must be a number
  //     transport: "xhr", // optional, beacon/xhr/image
  //   });
  // }
}

export function addToCartAnalytics(appProps){
  // if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
  //   ReactPixel.trackCustom('AddToCart', {
  //     content_name: `${ rental.title }`,
  //     content_ids: rental.id,
  //     content_type: "rental",
  //     content_category: `${ rental.prototypeName ? rental.prototypeName : '' }`,
  //     value: `${rental.totalPrice }`,
  //     currency: `${appProps.currency}`
  //   })
  // }
  //
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ReactGA.event({
  //     category: `${ rental.prototypeName ? rental.prototypeName : 'No rental Category' }`,
  //     action: 'Add to cart',
  //     label: `${ rental.title }`, // optional
  //     value: `${ rental.totalPrice }`,
  //     transport: "xhr", // optional, beacon/xhr/image
  //   });
  // }
}

export function checkoutEmailAnalytics(){
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ReactGA.event({
  //     category: 'Checkout',
  //     action: 'Customer email added',
  //     label: 'Customer (hidden)', // optional
  //     transport: "xhr", // optional, beacon/xhr/image
  //   });
  // }
}

export function checkoutDeliveryMethodAnalytics(){
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ReactGA.event({
  //     category: 'Checkout',
  //     action: 'Delivery method selected',
  //     label: `${deliveryOption}`, // optional
  //     transport: "xhr", // optional, beacon/xhr/image
  //   });
  // }
}

export function checkoutShippingMethodAnalytics(){
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ReactGA.event({
  //     category: 'Checkout',
  //     action: 'Shipping method selected',
  //     label: `${selectedMethod.type}`, // optional
  //     transport: "xhr", // optional, beacon/xhr/image
  //   });
  // }
}

export function checkoutPaymentMethodAnalytics(){
  // if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
  //   ReactGA.event({
  //     category: 'Checkout',
  //     action: 'Payment method selected',
  //     label: `${selectedMethod.title}`, // optional
  //     transport: "xhr", // optional, beacon/xhr/image
  //   });
  // }
}
