import React, { useState, useEffect } from 'react'
import ReservationBox from './ReservationBox'
import { toURLEncoded } from 'helpers/utils'

export default function FilterRentals({ appProps, filterRentalsPath, setRentals, setFilteredDates }) {
  const [guests, setGuests] = useState(1)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)

  function handleFilter() {
    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: filterRentalsPath,
      dataType: 'json',
      data: toURLEncoded({
        guests: guests,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD')
      }),
      success: (result) => {
        setRentals(result.rentals)
        setFilteredDates(`filter_start_date=${startDate.format('YYYY-MM-DD')}&filter_end_date=${endDate.format('YYYY-MM-DD')}&filter_guests=${guests}`)
        setLoading(false)
        document.getElementsByClassName('rental-list-items')[0].scrollIntoView({ behavior: 'smooth' })
      },
      error: (result) => { setLoading(false) }
    })
  }

  return (
    <ReservationBox
      appProps={ appProps }
      guests={ guests }
      setGuests={ setGuests }
      startDate={ startDate }
      setStartDate={ setStartDate }
      endDate={ endDate }
      setEndDate={ setEndDate }
      buttonAction={ handleFilter }
      buttonText={ appProps.translations.rentals.check_availability }
      loading={ loading }
    />
  )
}
