import React from 'react'
import RentalListItem from './RentalListItem'

export default function RentalListItems({ appProps, rentals, rentalListVersion, itemsPerRow, filtersDates }) {

  return (
    <div className='rental-list'>
      <div className='rental-list-items'>
        { rentals.length > 0 ?
          <>
            { rentals.map( rental => (
              <a
                href={`${filtersDates ? rental.link + '?' + filtersDates : rental.link }`}
                key={ rental.id }
                className={ `${ rentalListVersion } rental-item ${ itemsPerRow || '' }` }
              >
                <RentalListItem
                  appProps={ appProps }
                  rental={ rental }
                  rentalListVersion={ rentalListVersion }
                />
              </a>
            ))}
          </>
        :
          appProps.translations.rentals.no_rentals_found
        }
      </div>
    </div>
  )
}
