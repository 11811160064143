import React from "react"

export const IncompleteStep = (props) => {
  return(
    <div className="card checkout-selected-step flex-box items-center close_icon incomplete">
      <div className="selection-name">
        { props.stepTitle }
      </div>
      <div className="selection-option">
        <div>
        </div>
        <div className="change-option">
        </div>
      </div>
    </div>
  )
}
