import React, { useState, useEffect } from 'react'
import { Modal } from '@reactiveonline/frontend_shared_components'

export default function TermsAndAgreements({
  appProps, legalTerms, agreements,
  acceptedAgreements, acceptedTerms, setAcceptedAgreements, setAcceptedTerms
}) {
  const [showTerms, setShowTerms]           = useState(false)
  const [showAgreements, setShowAgreements] = useState(false)

  function escFunction(event){
    if (event.key === "Escape") {
      if (showTerms) {
        setShowTerms(false)
      }
      if (showAgreements) {
        setShowAgreements(false)
      }
    }
  }
  useEffect( ()=>{
    document.addEventListener("keydown", escFunction, false);
  },[showTerms, showAgreements])

  return (
    <>
      <div className="field accept-agreements">
        <div>
          <div className="switch-wrapper">
            <div className="flex-box items-center">
              <label className="switch">
                <input
                  id="accept-agreements"
                  type="checkbox"
                  name="acceptedAgreements"
                  value="1"
                  defaultChecked={ acceptedAgreements }
                  onChange ={ (e) => setAcceptedAgreements(e.target.checked) }
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div
              id="agreement-text"
              className="switch-text pointer link"
              onClick={ () => setShowAgreements(true) }
            >
              { appProps.translations.general.button_accept } { appProps.translations.reservations.agreement }
            </div>
          </div>
        </div>
      </div>

      <div className="field accept-terms">
        <div>
          <div className="switch-wrapper">
            <div className="flex-box items-center">
              <label className="switch">
                <input
                  id="accept-terms"
                  type="checkbox"
                  name="acceptedTerms"
                  value="1"
                  defaultChecked={ acceptedTerms }
                  onChange ={ (e) => setAcceptedTerms(e.target.checked) }
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div
              id="term-text"
              className="switch-text pointer link"
              onClick={ () => setShowTerms(true) }
            >
              { appProps.translations.general.button_accept } { appProps.translations.general.terms_and_conditions }
            </div>
          </div>
        </div>
      </div>

      { showTerms &&
        <Modal
          visible
          mode='medium'
          closeModal={ ()=> setShowTerms(false) }
        >
          { legalTerms  && <div dangerouslySetInnerHTML={{__html: legalTerms }} className="text"></div> }
        </Modal>
      }

      { showAgreements &&
        <Modal
          visible
          mode='medium'
          closeModal={ ()=> setShowAgreements(false) }
        >
          <div dangerouslySetInnerHTML={{__html: agreements.description }} className="text"></div>
        </Modal>
      }
    </>
  )
}
