import React, { useState } from 'react'
import PaymentStep from './PaymentStep'
import { AuthorizableModal } from '@reactiveonline/frontend_shared_components'
import Summary from './Summary'

export default function PolicyPaymentLink( props ) {
  const { appProps, reservation } = props
  const { translations } = appProps

  const [billingAddress, setBillingAddress] = useState(props.userAddress)
  const [dateRange, setDateRange] = useState(reservation.date_range)
  const [email, setEmail] = useState(reservation.email)
  const [showPaymentIframe, setShowPaymentIframe] = useState(false)
  const [rental, setRental] = useState(reservation.rental)
  const [cost, setCost] = useState(reservation.cost)

  return (
    <>
      <div className='main-container'>
        { !appProps.user && reservation.rental.policy ?
          <AuthorizableModal
            appProps={ appProps }
            authorizableScreen={ 'login' }
          />
          :
          <div className='checkouts-wrapper'>
            <Summary
              appProps={ appProps }
              currentRental={ rental }
              cost={ cost }
            />

            <div className='checkout-container'>
              <div className='card'>
                <div className='card-content'>
                  <div className='card-title'>
                    <h3>
                      { `${ translations.reservations.payment_transaction_fail }: ${ props.reservation.number }` }
                    </h3>
                  </div>

                  <div className='card-body'>
                    <div>
                      { `${ translations.reservations.payment_failure_reason }: ${ props.payment.error_message }` }
                    </div>

                    { props.payment.customFields.find(el => el.key === 'damage_protection_charge_reason') &&
                      <div style={{ margin: '20px 0' }}>
                        <div>
                          { translations.reservations.damage_protection_host_reason }
                        </div>
                        <div>
                          <b>
                            { `${ props.payment.customFields.find(el => el.key === 'damage_protection_charge_reason').value }` }
                          </b>
                        </div>
                      </div>
                    }

                    <div>
                      { `${ translations.reservations.payment_process_help_text } ${ props.payment.display_amount }` }
                    </div>
                  </div>
                </div>
              </div>

              <PaymentStep
                appProps={ appProps }
                autoAcceptTermsAndAgreements={ true }
                showPaymentIframe = { showPaymentIframe }
                indirect
                dateRange={ dateRange }
                stripePublishableKey = { props.stripePublishableKey }
                orderId = { props.orderId }
                type = { props.type }
                cards = { props.cards }
                setCurrentCardPath = { props.setCurrentCardPath }
                cardsPath = { props.cardsPath }
                addNewCardText = { props.addNewCardText }
                guestCheckout = { !appProps.currentUserEmail }
                paymentId = { props.payment.id }
                reservationPath={ props.payPolicyByLinkPath }
                payableAlreadyCompleted={ true }
                billingDetails = { {
                  address: {
                    city: billingAddress.city,
                    country: billingAddress.country_alpha2_code,
                    line1: billingAddress.street,
                    postal_code: billingAddress.postal_code,
                    state: billingAddress.state_name
                  },
                  email: email,
                  name: billingAddress.full_name,
                  phone: billingAddress.mobile_phone || billingAddress.phone
                } }
              />
            </div>
          </div>
        }
      </div>
    </>
  )
}
