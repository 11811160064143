import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IncompleteStep } from './Steps'
import { Loader } from '@reactiveonline/frontend_shared_components'
import ShowMore from './rentalShowMore'
import GuestSelector from '../rentals/widget/GuestSelector'
import { NO_IMAGE_SOURCE } from '../rentals/constants'

const RentalSelectStep = (props) => {
  const [rentals, setRentals] = useState([])
  const {
    appProps, currentStep, getRentals, isLoading, onUpdateReservation,
    rental, onSetCurrent, guests, setGuests
  } = props

  useEffect(() => {
    getRentals(setRentals)
  }, [])

  if (currentStep > 2){
    return(
      <div className='card checkout-selected-step flex-box items-center checked_icon' onClick={ onSetCurrent }>
        <div className='edit_icon flex-box items-center content-center' />
        <div className='rental-details'>
          <div><b>{ rental.name }</b></div>
          <div className='details'>
            <div className='detail'> <i className="far fa-user"/> { guests } </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className="card checkout-step flex-box flex-column">
      <h3><b>{ appProps.translations.reservations.edit_reservation }</b></h3>

      <Editor
        appProps={ appProps }
        currentRental = { rental }
        rentals = { rentals }
        isLoading = { isLoading }
        onUpdateReservation = { onUpdateReservation }
        guests={ guests }
        setGuests={ setGuests }
      />

      <div className='button-wrapper flex-box content-end' style={{ marginTop: 40 }}>
        { isLoading ?
          <div className='button inverted flex-box items-center content-center'>
            <Loader size='medium'/>
          </div>
          : currentStep > 1 &&
          <div
            className='button'
            onClick={ () => { rental && onUpdateReservation({ guests: guests }, 3) } }
            disabled = { typeof rental !== 'object' }>
            { appProps.translations.reservations.next_step }
          </div>
        }
      </div>

    </div>
  )
}

const Editor = ({ appProps, currentRental, rentals, isLoading, onUpdateReservation, guests, setGuests }) => {
  if(currentRental){
    return(
      <Rental
        appProps={ appProps }
        isCurrent={ true }
        rental={ currentRental }
        onUpdateReservation={ onUpdateReservation }
        guests={ guests }
        setGuests={ setGuests }
      />
    )
  }

  if(isLoading) return loader

  return (
    rentals.length > 0 && rentals.map((rental, index) => {
      return(
        <Rental
          key={index}
          appProps={ appProps }
          rental={rental}
          onUpdateReservation={ onUpdateReservation }
          guests={ guests }
          setGuests={ setGuests }
        />
      )
    })
  )
}

const Rental = (props) => {
  const { isCurrent, rental: { id, arrangements, images, information, name }, rental, translations, onUpdateReservation, guests, setGuests } = props
  const setCurrentRental = () => onUpdateReservation({ rental_id: isCurrent ? null : id })

  return(
    <div className='step-editor flex-box flex-wrap'>
      <div className='rental-image'>
        <img src={ images.length > 0 ? images[0].thumbnail : NO_IMAGE_SOURCE } alt='thumbnail'/>
      </div>
      <div className='rental-details rental-info'>
        <div className='rental-detail name'>
          { name }
        </div>
        <div className='rental-detail amenities'>
          <div className='detail'> <i className="far fa-user"/> { guests } </div>
          <div className='detail'> <i className='fad fa-bed-alt'/> { arrangements.size } </div>
          <div className='detail'> { information.area } </div>
        </div>
        <div className='rental-detail amenities'>
          <div className='detail'>
            <ShowMore
              appProps={ props.appProps }
              rental={ rental }
            />
          </div>
        </div>

        { isCurrent ?
          <>
            <GuestSelector
              appProps={ props.appProps }
              guests={ guests }
              setGuests={ setGuests }
            />
            <button className='button plain remove-rental remove_icon' type='button' onClick={ setCurrentRental }/>
          </>
        :
          <button className='button remove-rental select_icon' type='button' onClick={ setCurrentRental }>
            { props.appProps.translations.reservations.select_reservation }
          </button>
        }
      </div>
    </div>
  )
}

export default RentalSelectStep

RentalSelectStep.propTypes = {
  currentStep: PropTypes.number,
  getRentals: PropTypes.func,
  isLoading: PropTypes.bool,
  onSetCurrent: PropTypes.func,
  rental: PropTypes.object,
  onUpdateReservation: PropTypes.func,
}
